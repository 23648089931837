.dashboard-block {
	border: 1px solid $color-light-gray;
	padding: 30px;
	border-radius: 3px;
	background: $color-white;
	overflow: hidden;
	margin-bottom: 20px;
}

.dashboard-title {
	font-size: 24px;
}

.onboarding-title {
	text-align: center;
	display: block;
	font-size: 18px;
	margin-bottom: 15px;
}

.onboarding-list {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.onboarding-list-item {
	padding: 15px 0px;
    font-weight: 600;
    font-size: 16px;

    i {
    	margin-right: 5px;
    	border-radius: 50%;
    	padding: 5px 5px 5px 6px;
    }

	&.completed {
		i {
			border: 2px solid #b8ffb8;
		    background: #b8ffb8;
			color: #12ac8e !important;
		}
		color: $color-gray;
		text-decoration: line-through;
	}
    
    a {
    	i {
    		border: 2px solid #efefef;
		    background: #efefef;
    		color: $color-gray;
    	}

    	color: #12ac8e;
    }
}

.quick-links {

	text-align: center;

	i {
		font-size: 33px;
	}

	a {
		color: $color-dark-gray;
	}

	p {
	    font-size: 18px;
	    margin-top: 15px;
	    margin-bottom: 0px;
	    font-weight: 600;
	}

	.coming-soon {
		position: absolute;
	    top: 10px;
	    right: 25px;
	    background: #ee3594;
	    color: #FFF;
	    padding: 3px 5px;
	    font-size: 10px;
	    border-radius: 3px;
	    font-weight: bold;
	}
}

/* SETTINGS PAGE STYLE */
.influencer-settings {

	background: #F4F4F4;

	h4 {
		text-transform: none;
		font-weight: 600;
		margin-bottom: 30px;
	}

  	.settings-title {
  		font-size: 18px;
  		margin-bottom: 15px;
  	}
  	
  	.form-group {
    	margin-top: 10px;
    	margin-bottom: 10px;
  	}
  	
  	.save_btn {
    	background-color: #523875;
    	color: #fff;
    	border-radius: 3px;
    	border: 0px solid;
    	margin-top: 25px;
  	}
  
  	.title_p {
    	margin-top: 20px;
    	margin-bottom: 0px;
  	}
  	
  	.desc_p {
    	margin-top: 0px;
    	margin-bottom: 10px;
  	}
  
  	.first_title_p {
    	margin-top: 45px;
  	}
}

