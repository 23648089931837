.outer_container {

    &.grey-white-colored {
        // background: url('../img/container_bg.jpg') 50% 0 repeat-y;
        padding: 60px 0 0 0;

        // @media only screen and (max-width: 991px) {
        //     background: #f4f4f4 none;
        // }
    }

    @media only screen and (max-width: 992px) {
        padding: 0px 0px 60px;
        background: $color-white;
    }

    @media only screen and (max-width: 480px) {
        padding: 0px 0px 60px;
        background: $color-white;
    }
}

.overlay_sample {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.40);
    text-align: center;
    padding: 75px 30px;
    display: none;

    .overlay_text {
        border: 1px solid #FFF;
        color: #FFF;
        padding: 5px 10px;
        font-weight: 600;
    }
}

.infl-avatar:hover .overlay_sample {
    display: block;
}

.white-container {
    background: #FFF;
    padding-top: 30px;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;
    // border-left: 1px solid #DBDBDB;

    @media (max-width: 1200px) {
        min-width: 100%!important;
    }

    @media only screen and (max-width: 480px) {
        // padding: 0px 15px;
        padding-top: 0px;
        border-left: 0px;
    }
}

.community {
    .influencer-result {
        height: auto !important;
        min-height: 255px !important;
    }
}

.total_credits {
    font-size: 24px;
    padding-top: 2px;
    font-family: 'Bebas Neue Regular', sans-serif;
}

.right_left {
    text-align: right;
}

.searched_area {
    background: #FFF;
    padding-top: 35px;
    padding-bottom: 50px;
    //padding-left: 30px;
    //.nextBtn {
    //  display: none;
    //}

    #influencerListing:before {
        width:0!important;
        }
}

#filter_mobile {
    display: none;
    button {
        max-width: 100%;
        padding: 0px;
        border: 0px solid;
        text-align: right;
        &:hover {
            background-color: $color-white;
            border: 0px solid;
            color: #637280;
        }
    }
}

.hide-name {
    height: 15px;
    background: rgba(210, 212, 216, 0.44);

}

.contact {

    li {

        margin-bottom: 10px;

        span.contact-icon {
            float: left;
            font-size: 15px;
            color: rgba(169, 177, 185, 0.5);

            &.address {
                font-size: 20px;
                margin-top: -6px;
                margin-left: -2px;
            }

            &.phone {
                font-size: 20px;
                margin-top: -2px;
            }
        }

        span.contact-info {
            padding-left: 25px;
            display: block;
        }
    }
}

.add_showcase {
    min-height: 250px;
    .add_showcase_image {
        cursor: pointer;
    }
    .overlay_file {
        height: 100%;
        max-width: 90%;
        position: absolute;
        top: 0;
        width: 100%;
        cursor: pointer;
        opacity: 0;
    }
    .showcase_image {
        height: 100%;
        padding: 42% 0 0;
        position: absolute;
        top: 0;
        width: 90%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        i {
            font-size: 18px;
            color: $color-white;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    .remove_showcase {
        position: absolute;
        right: -24px;
        width: 50px;
        top: -9px;
        height: 20px;
        a {
            cursor: pointer;
            border: 2px solid $color-gray;
            border-radius: 50%;
            padding: 0px 5px;
            color: $color-gray;
            font-size: 14px;
            background-color: $color-white;
        }
    }
    .showcase_updated {
        display: none;
    }

}

#show-case .item .img {
    padding-bottom: 100%;
    background-size: cover !important;
    background: transparent no-repeat scroll center;
}




.influencer-profile-edit{
    font-size: 14px;

    @media only screen and (max-width: 991px) {
        display: inline-block;
        width: 100%;
    }

    .info-not-provided {
        color: #a5a5a5;
        font-style: italic;
    }

    .bio {
        line-height: 26px;
    }

    h5.title {
        padding-top: 0px;
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .tag {

        margin-bottom: 10px;
        float: left;
        margin-right: 10px;

        span {
            padding: 8px 15px;
            border-radius: 0px;

            &.tag-orange {
                a {
                    color: $color-dark-gray;
                }
                font-size: 14px;
                background: #f4f4f4;
            }
        }
    }

    .bootstrap-tagsinput {
        border-radius: 0px;
        padding: 5px;
        .tag {
            line-height: 20px;
            background-color: $color-gray;
            border-radius: 0px;
            margin-right: 3px;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 5px;
            span {
                padding: 0px 0px;
                border-radius: 0px;

                &.tag-orange {
                    a {
                        color: $color-dark-gray;
                    }
                    font-size: 14px;
                    background: #f4f4f4;
                }
            }
        }
    }

    .full-width {
        width: 100%;
    }

    .edit-my-profile {
        @media only screen and (max-width: 480px) {
            display: none;
        }
    }

    .social-block {
        text-align: center;
        font-size: 14px;
        margin-bottom: 25px;
        padding: 0 2px 0;

        @media (max-width: 536px) {
            font-size: 13px;
            //letter-spacing: -0.2px;
        }

        .social-icon-wrap {
            .social-icon {
                color: white;
                background: #e8e8e8;
                width: 50px;
                height: 50px;
                display: inline-block;
                border-radius: 50%;
                font-size: 25px;
                line-height: 50px;
                margin-bottom: 10px;

                &.facebook {
                    background-color: #3b5998;
                }

                &.twitter {
                    background-color: #1da1f2;
                }

                &.instagram {
                    background-color: #e4405f;
                }

                &.youtube {
                    background-color: #cd201f;
                }

                &.snapchat {
                    background-color: #f3f010;
                }
            }
        }
    }

    .influencer-sidebar {

        display: block;
        background-color: #F4F4F4;
        padding: 30px;

        @media (max-width: 1200px) {
            min-width: 100%!important;
        }

        @media (max-width: 500px) {
            .btn-orange {
                padding: 8px;
            }
        }

        .alias {
            font-weight: 600;
            margin: 5px 0px 15px;
        }

        .editable-buttons {
            button {
                padding: 0px;
                i {
                    line-height: 28px;
                    text-align: center;
                    width: 32px;
                    height: 28px;
                }
                .fa-close {
                    color: $color-dark-gray;
                }
                .btn-primary {
                    color: #fff;
                    background-color: #4CAF50;
                    border-color: #4CAF50;
                }
            }
        }
    }

    .editable-container.editable-inline {
        width: 100%;
        float: left;
        .control-group {
            width: 100%;
            float: left;
            .editable-input {
                width: 100%;
                float: left;
                textarea {
                    width: 100%;
                    float: left;
                    border-radius: 0px;
                }
            }
            .editable-buttons {
                width: 100%;
                float: left;
            }
        }
    }

    .inline-textarea {
        color: $color-dark-gray;
        border-bottom: 0px solid;
        // padding: 25px;
    }

    .inline-textarea:hover {
        border-bottom: 0px solid;
        background-color: #f4f4f4;
    }

    // .inline-textarea:hover::before {
    //   content: "\f040";
    //   font-family: FontAwesome;
    //   right: 10px;
    //   position: absolute;
    //   top: 30px;
    //   font-size: 22px;
    //   color: $color-dark-gray;
    // }

    .social-wrap{
        display: flex; 
        justify-content: center;

        @media only screen and (max-width: 800px) {
            flex-wrap: wrap;
        }
        .social-item{

            @media only screen and (max-width: 550px) {
                width: 100%!important;
            }
        }
    }

    .interest-wrap {
        list-style: none;
        padding: 0px;
        margin: 0 0 0 0;
        display: flex;
        justify-content: center;
        


        @media only screen and (max-width: 600px) {
            flex-wrap: wrap;
        }

    }

    .interest_list {
        .checkbox {
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }

    .tokenfield {

        &.form-control {
            border-radius: 0px;
            padding: 6px;
        }

        .token {
            background-color: $color-gray;
            border-radius: 0px;
            border: 1px solid $color-gray;
            color: $color-white;
            .close {
                color: $color-white;
                line-height: 1.3em;
                opacity: 1;
                margin-left: 7px;
            }
            .token-label {
                padding-left: 7px;
            }
        }
    }

    .custom_btn {
        font-size: 17px;
        padding: 3px 12px;
        font-family: $font-Bebas-Neue-Bold !important;
        border-radius: 3px !important;
    }

    .color_facebook {
        color: #3b5998;
    }

    .color_twitter {
        color: #1da1f2;
    }

    .color_instagram {
        color: #e4405f;
    }

    .color_youtube {
        color: #cd201f;
    }

    .color_other {
        color: $color_orange;
    }

    .remove_rates {
        cursor: pointer;
    }

    .upload_list {
        li {
            padding: 0px 0px 5px 0px;
            a {
                color: $color-dark-gray;
            }
        }
    }

    .brand-wrap {
        margin: 30px 0px 0px;
    }
}






.influencer-profile {
    width: 100%;
    max-width: 800px;
    font-size: 14px;

    @media only screen and (max-width: 991px) {
        display: inline-block;
        width: 100%;
    }

    .info-not-provided {
        color: #a5a5a5;
        font-style: italic;
    }

    .bio {
        line-height: 26px;
    }

    h5.title {
        padding-top: 0px;
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .tag {

        margin-bottom: 10px;
        float: left;
        margin-right: 10px;

        span {
            padding: 8px 15px;
            border-radius: 0px;

            &.tag-orange {
                a {
                    color: $color-dark-gray;
                }
                font-size: 14px;
                background: #f4f4f4;
            }
        }
    }

    .bootstrap-tagsinput {
        border-radius: 0px;
        padding: 5px;
        .tag {
            line-height: 20px;
            background-color: $color-gray;
            border-radius: 0px;
            margin-right: 3px;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 5px;
            span {
                padding: 0px 0px;
                border-radius: 0px;

                &.tag-orange {
                    a {
                        color: $color-dark-gray;
                    }
                    font-size: 14px;
                    background: #f4f4f4;
                }
            }
        }
    }

    .full-width {
        width: 100%;
    }

    .edit-my-profile {
        @media only screen and (max-width: 480px) {
            display: none;
        }
    }

    .social-block {
        text-align: center;
        font-size: 14px;
        margin-bottom: 25px;
        padding: 0 2px 0;

        @media (max-width: 536px) {
            font-size: 13px;
            //letter-spacing: -0.2px;
        }

        .social-icon-wrap {
            .social-icon {
                color: white;
                background: #e8e8e8;
                width: 50px;
                height: 50px;
                display: inline-block;
                border-radius: 50%;
                font-size: 25px;
                line-height: 50px;
                margin-bottom: 10px;

                &.facebook {
                    background-color: #3b5998;
                }

                &.twitter {
                    background-color: #1da1f2;
                }

                &.instagram {
                    background-color: #e4405f;
                }

                &.youtube {
                    background-color: #cd201f;
                }

                &.snapchat {
                    background-color: #f3f010;
                }
            }
        }
    }

    .influencer-sidebar {

        display: block;
        background-color: #F4F4F4;
        padding: 30px;

        @media (max-width: 1200px) {
            min-width: 100%!important;
        }

        @media (max-width: 500px) {
            .btn-orange {
                padding: 8px;
            }
        }

        .alias {
            font-weight: 600;
            margin: 5px 0px 15px;
        }

        .editable-buttons {
            button {
                padding: 0px;
                i {
                    line-height: 28px;
                    text-align: center;
                    width: 32px;
                    height: 28px;
                }
                .fa-close {
                    color: $color-dark-gray;
                }
                .btn-primary {
                    color: #fff;
                    background-color: #4CAF50;
                    border-color: #4CAF50;
                }
            }
        }
    }

    .editable-container.editable-inline {
        width: 100%;
        float: left;
        .control-group {
            width: 100%;
            float: left;
            .editable-input {
                width: 100%;
                float: left;
                textarea {
                    width: 100%;
                    float: left;
                    border-radius: 0px;
                }
            }
            .editable-buttons {
                width: 100%;
                float: left;
            }
        }
    }

    .inline-textarea {
        color: $color-dark-gray;
        border-bottom: 0px solid;
        // padding: 25px;
    }

    .inline-textarea:hover {
        border-bottom: 0px solid;
        background-color: #f4f4f4;
    }

    // .inline-textarea:hover::before {
    //   content: "\f040";
    //   font-family: FontAwesome;
    //   right: 10px;
    //   position: absolute;
    //   top: 30px;
    //   font-size: 22px;
    //   color: $color-dark-gray;
    // }

    .social-wrap{

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .social-item{
            @media only screen and (max-width: 550px) {

                img{
                    width: 25px!important;
                }
            }
        }
    }

    .interest-wrap {
        list-style: none;
        padding: 0px;
        margin: 0 0 0 0;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;


        // @media only screen and (max-width: 700px) {
        //     flex-wrap: wrap;
        // }

        .interest-item {
            text-align: center;
            margin: 20px 0;
            padding: 0 2px 0;

            height: 80px!important;

            // width: 100%;

            

            // @media only screen and (max-width: 700px) {                
            //     width: 600px!important;
            // }


            // @media only screen and (max-width: 600px) {
            //     width: 120px;
            // }


            h6 {
                text-transform: none;
                font-weight: 400;
                font-size: 14px;
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
    }

    .interest_list {
        .checkbox {
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }

    .tokenfield {

        &.form-control {
            border-radius: 0px;
            padding: 6px;
        }

        .token {
            background-color: $color-gray;
            border-radius: 0px;
            border: 1px solid $color-gray;
            color: $color-white;
            .close {
                color: $color-white;
                line-height: 1.3em;
                opacity: 1;
                margin-left: 7px;
            }
            .token-label {
                padding-left: 7px;
            }
        }
    }

    .custom_btn {
        font-size: 17px;
        padding: 3px 12px;
        font-family: $font-Bebas-Neue-Bold !important;
        border-radius: 3px !important;
    }

    .color_facebook {
        color: #3b5998;
    }

    .color_twitter {
        color: #1da1f2;
    }

    .color_instagram {
        color: #e4405f;
    }

    .color_youtube {
        color: #cd201f;
    }

    .color_other {
        color: $color_orange;
    }

    .remove_rates {
        cursor: pointer;
    }

    .upload_list {
        li {
            padding: 0px 0px 5px 0px;
            a {
                color: $color-dark-gray;
            }
        }
    }

    .brand-wrap {
        margin: 30px 0px 0px;
    }
}

#left_drawer {
    display: none;
    background-color: #f4f4f4;
    //background-color: rgba(6, 14, 49, 0.98);
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    z-index: 9999;
    min-width: 250px;
    padding: 15px 15px;

    ul {
        padding-left: 0;
        list-style-type: none;

    }

    .example .rollbar-path-horizontal {
        height: 3px;
        background: rgba(255, 255, 255, 0.2);
    }
    .example .rollbar-path-vertical {
        width: 3px;
        background: rgba(255, 255, 255, 0.2);
    }
    .example .rollbar-path-vertical, .example .rollbar-path-horizontal {
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
    .selectize-input {
        border-width: 1px;
    }
    label {
        color: #637280;
    }
    .filters {
        margin-bottom: 150px;
        padding: 0px 21px 0px 0px !important;
    }
}

#right_drawer_empty {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9998;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
    position: relative;
    cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -moz-transition: opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition: opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
    z-index: 1001;
    border: 6px solid transparent;
    background: transparent;
    content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
    z-index: 1000;
    padding: 15px;
    min-width: 170px;
    background-color: #000;
    background-color: #444f58;
    color: #fff;
    content: attr(data-tooltip);
    font-size: 13px;
    border-radius: 3px;
    text-transform: none;
    /* text-align: center !important; */
    line-height: 18px;
    font-style: normal;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
    bottom: 100%;
    left: 50%;
    margin-left: -4px;
    border-top-color: #FFF;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
    margin-left: -6px;
    margin-bottom: -12px;
    border-top-color: #000;
    border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
    margin-left: -80px;
    text-align: center !important;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
    right: 100%;
    bottom: 50%;
    left: auto;
}

.tooltip-left:before {
    margin-left: 0;
    margin-right: -12px;
    margin-bottom: 0;
    border-top-color: transparent;
    border-left-color: #000;
    border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
    -webkit-transform: translateX(-12px);
    -moz-transform: translateX(-12px);
    transform: translateX(-12px);
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
    top: 100%;
    bottom: auto;
    left: 50%;
}

.tooltip-bottom:before {
    margin-top: -12px;
    margin-bottom: 0;
    border-top-color: transparent;
    border-bottom-color: #000;
    border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
    -webkit-transform: translateY(12px);
    -moz-transform: translateY(12px);
    transform: translateY(12px);
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
    bottom: 50%;
    left: 100%;
}

.tooltip-right:before {
    margin-bottom: 0;
    margin-left: -12px;
    border-top-color: transparent;
    border-right-color: #000;
    border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    transform: translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
    top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
    margin-left: 0;
    margin-bottom: -16px;
}

#ui-id-1 {
    max-width: 700px !important;
    list-style-type: none;
    padding: 0;
    background-color: $color-white !important;
    border: 1px solid $color-dark-gray;
    li.ui-menu-item {
        float: left;
        width: 100%;
        background-color: $color-white;
        a {
            float: left;
            width: 100%;
            padding: 5px 20px;
            text-decoration: none;
        }
        a:hover {
            color: $color-white;
            background-color: #7365c0;
        }
    }
}

.mobile-profile {
    display: none;
    padding: 90px 15px 0px;

    @media only screen and (max-width: 480px) {
        display: block;
    }

    .header {
        text-align: center;
    }

    .name {
        margin: 15px 0px 5px;
    }

    .alias {
        font-weight: 600;
        margin: 0px 0px 10px;
    }

    .summary li {
        margin-bottom: 0px;
    }

    .social-block {

        overflow: hidden;
        margin-top: 15px;

        .social-icon-wrap {
            float: left;
            margin-right: 8px;

            .social-icon {
                float: left;
            }

            .social-count {
                padding-left: 35px;
                margin-top: 0px;
                margin-left: 3px;
                font-size: 14px;
                padding-top: 5px;
            }

            i {

                color: $color-white;
                border-radius: 50%;
                font-size: 16px;
                line-height: 32px;
                text-align: center;
                width: 32px;
                height: 32px;

                &.fa-facebook {
                    background: #3b5998;
                }

                &.fa-twitter {
                    background: #1da1f2;
                }

                &.fa-instagram {
                    background: #e4405f;
                }

                &.fa-youtube-play {
                    background: #cd201f;
                }
            }
        }
    }
}

a.editable-click {
    color: $color-dark-gray
}

.editable-click, a.editable-click, a.editable-click:hover {
    text-decoration: none;
    border-bottom: 0px;
}

.pill-locations{

    .tag-pill {

        &.tag-pill-button {
            padding: 3px 10px 3px 25px !important;
        }
    
        float: left;
        margin: 3px 3px;
        padding: 3px 7px;
        border-radius: 0px;
        border: 1px solid #dbe2e8;
        background-color: #fff;
        box-sizing: border-box;
        max-width: 100%;
        position: relative;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    
        a {
            color: $color-dark-gray;
        }
    }

}

.tag-pill {

    &.tag-pill-button {
        padding: 3px 10px 3px 25px !important;
    }

    float: left;
    margin: 13px 13px 0 0;
    padding: 3px 7px;
    border-radius: 0px;
    border: 1px solid #dbe2e8;
    background-color: #fff;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    .has-delete {
        padding-right: 27px;
    }

    a {
        color: $color-dark-gray;
    }

    .new-delete {
        width: 12px;
        position: absolute;
        top: -10px;
        right: -15px;
    }

    &.tag-private {
        background: #444F58;
        color: white;
    }
}

.tag-pill, .t__group-label, .t__h4 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: $color-dark-gray;
}

.tag-pill__icon {
    top: 4px;
    left: 9px;
    position: absolute;
}

.t__solo-link, .t__solo-link__outer {
    text-decoration: none;
    cursor: pointer;
}

.tag-pill__delete {
    right: 8px;
    top: 3px;
    position: absolute;
}

.modal-content {
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #dfdfdf;
}

.modal-title {
    font-weight: 600;
}

.edit-name-pencil {
    position: absolute;
    top: 0px;
    right: 0px;
    // font-size: 18px;
    color: $color-dark-gray;
}

.modal-content {
    label {
        font-weight: 600;
    }

    .form-control {
        border-radius: 0px;
    }

    .selectize-input {
        border: 1px solid #CCCCCC;
    }
}

.popover.top {
    border-radius: 0px;
    .form-control {
        border-radius: 0px;
    }
}

.popover-title {
    text-transform: none;
}

.media-gal .thumbnail {
    padding: 8px;
    border: 1px solid $color-light-gray;
    border-radius: 0px;
}

.media-gal .item .btn-remove {
    position: absolute;
    top: -13px;
    right: 7px;
    z-index: 888;
    cursor: pointer;
}

// .media-gal .item:hover img {
//     opacity: .8;
// }

// .media-gal .item:hover .btn-remove{
//     display: block;
// }

// .media-gal .item.default .btn-remove{
//     display: none;
// }

// .selectize-control.multi .selectize-input.has-items {
//     border: 1px solid #DFDFDF;
// }

.delete-rate-icon {
    color: $color-dark-gray;
    margin-left: 10px;
    font-size: 16px;
}

// .selectize-control.multi .selectize-input [data-value] {
//     background: $color-gray;
// }

.btn-edit-section {
    color: #444f58;
    padding: 0px 6px 0px 6px;
    font-weight: 400;
    font-size: 12px;
    font-weight: 400;
    margin-left: 15px;
    margin-top: -2px;
    background: #FFF !important;
    text-transform: none !important;

    &.gray-bg {
        background: #F4F4F4 !important;
    }

    &:hover {
        background: $color-dark-gray !important;
        color: #FFF !important;
    }
}

.influencer-avatar {
    margin: 0 auto 20px;
    width: 100%;
    max-width: 200px;
    position: relative;

    .fake-avatar {
        width: 100%;
        padding-bottom: 100%;
        background-size: cover;
        border-radius: 50%;
        position: relative;
    }

    #image-list.overlay {
        position: absolute;
        display: inline-block;
        padding-bottom: 100%;
        width: 100%;
        top: 0;
        left: 0;

        &:after {
            content: " ";
            background: transparent url(/assets/web/img/upload_new_photo.png) no-repeat scroll center;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            top: 0;
            background-size: 100%;
        }
    }

    .avatar {
        margin: 0 auto;
        max-width: 200px;
        width: 100%;
    }

    .overlay_file {
        cursor: pointer;
        height: 100%;
        opacity: 0;
        width: 100%;
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
    }
}

.selectize-input {
    border: 1px solid $color-light-gray !important;
    box-shadow: none;
    border-radius: 0px;
}

.infl-name {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;

    @media (max-width: 600px) {
        font-size: 16px;
    }
}

.infl-followers {
    text-align: center;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: 991px) {
        font-size: 14px;
    }
}

.ks-avatar > img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.rounded-circle {
    border-radius: 50%;
}

.ks-comments {
    list-style: none;
    padding: 0;
    margin: 0;
}

.ks-comments .ks-comment {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    margin-bottom: 12px;
}

.ks-comments > .ks-comment:last-child {
    margin-bottom: 0;
}

.ks-comments > .ks-comment > .ks-body {
    font-size: 14px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 12px 15px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: relative;
}

.ks-comments > .ks-comment > .ks-body > .ks-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 2px;
}

.ks-comments > .ks-comment > .ks-body > .ks-header .ks-name {
    font-weight: 600;
}

.ks-comments > .ks-comment > .ks-body > .ks-header .ks-datetime {
    font-size: 12px;
    text-transform: uppercase;
    color: #858585;
}

.ks-comments > .ks-comment.ks-self > .ks-avatar {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.ks-comments > .ks-comment.ks-self > .ks-body {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    border: solid 1px #d7dceb;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 14px;
}

.ks-comments > .ks-comment.ks-self > .ks-body:before {
    content: '';
    display: block;
    position: absolute;
    left: -10px;
    top: -1px;
    width: 0;
    height: 0;
    border-top: 10px solid #d7dceb;
    border-right: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 10px solid transparent;
}

.ks-comments > .ks-comment.ks-self > .ks-body:after {
    content: '';
    display: block;
    position: absolute;
    left: -8px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 10px solid transparent;
}

.cropper-container {
    margin: 0 auto;
}

.ks-profile-progress-block {

    .ks-progress-info {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 10px;

        .ks-progress-header {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
            display: block;
        }

        .ks-progress-input {
            display: block;
            font-size: 12px;
        }

        .ks-progress-percent {
            font-size: 14px;
            font-weight: bold;
        }
    }

    .progress.ks-progress-bar {
        height: 10px;
    }

    .ks-progress-bar {
        margin: 0;
    }
}

.bg-danger {
    background-color: #de1236;
}

.bg-warning {
    background-color: #ff8400;
}

.bg-success {
    background-color: #24a35a;
}
