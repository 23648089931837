$heroLargeHeight: 620px;
$heroSmallHeight: 460px;

#main-nav-wrapper {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 60px !important;
  z-index: 999;
  padding: 0px;


  // -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
  // -moz-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
  // box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
}


#main-nav-wrapper .container-fluid {
  padding: 0px 30px;
  
  // @media (max-width: 768px) {
  //   // background-color: rgba(255, 255, 255, 1)!important;
  // background-color: rgba(255, 255, 255, 0.85);
  // }
}

#main-nav-wrapper.sticky {
  // -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
  // -moz-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
  // box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);

  // background-color: rgba(0, 0, 0, 0.85);
  background-color: rgba(255, 255, 255, 0.85);

  padding-bottom: 0;

  // @media only screen and (max-width: 480px){
  //   background-color: rgba(255, 255, 255, 0.85)!important;
  // }


  // ul{
    
  //   background-color: rgba(255, 255, 255, 0.85)!important;
  // }
  
}

.main-logo-wrapper {
  float: left;
  padding: 15px 0px;
}

#main-nav-wrapper .main-menu li a {
  font-size: 14px;
  color: $color-white;
  font-weight: 600;
  text-transform: uppercase;
  padding: 20px;

  .caret {
    margin-top: -2px;
  }
}

#main-nav-wrapper .main-menu .dropdown-menu li a {
  color: rgba(6, 14, 49, 0.85);
  font-size: 12px;
}

#main-nav-wrapper .navbar-nav>li>.dropdown-menu {
  margin-top: -1px;
}

nav.navbar li a {
  font-size: 14px;
  color: #E53E30!important;
  font-weight: 600;
  text-transform: uppercase;
  padding: 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;

  &:hover, &:focus, &:active{
    // background-color: white!important;
    color: #E53E30!important;
  }
}

nav.navbar .navbar-brand {
  padding: 12px 0px!important;

  @media (max-width: 991px) {
    
  padding: 8px 0px!important;
  }
}

.icon-bar {
  background-color: #E53E30!important;
}

nav.navbar .navbar-toggle {
  // border: 1px solid #E53E30;
  // border-radius: 0;
  // font-size: 12px;
  // padding: 4px 8px;
  // color: #E53E30;
  margin-right: 0px!important;
  // margin-top: 15px;
}

// nav .navbar-nav .nav_avatar {
//   padding-top: 23px !important;
//   padding-bottom: 3px !important;
//   img {
//     width: 30px;
//     height: 30px;
//     margin-right: 5px;
//   }
// }

nav .navbar-nav.influencer_logged_in .open > .nav_avatar,
nav .navbar-nav.influencer_logged_in .open > .nav_avatar:focus,
nav .navbar-nav.influencer_logged_in .open > .nav_avatar:hover {
  background-color: transparent white!important;
  border-color: transparent !important;
}

nav .navbar-nav.influencer_logged_in .open .dropdown-menu {
  background-color: white;
  min-width: 150px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px;
  box-shadow: none;

  li {
    a {
      padding: 7px 15px !important;
      color: $color-dark-gray;
      border-bottom: 1px solid #F4F4F4;
      font-weight: 400;
      font-size: 12px;
      i{
        margin-right: 5px;
      }
    }
    a:hover, a:focus, a:active {
      background-color:  rgba(255, 255, 255, 0.85);
      // color: $color-white !important;
    }
  }
}

.main-menu a {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

#main-nav-wrapper .main-menu li a.active {
  color: $color-pink;
}

#main-nav-wrapper .main-menu li a:hover {
  color: $color-pink;
}

.nav > li > a:focus, .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
  color: #E53E30!important;
}

@media (min-width: 768px) {
  .navbar-right {
    float: right !important;
    margin-right: -45px;
  }
}

.hero-wrap {
  display: block;
  width: 100%;
}

.hero-middle {
  display: table;
  width: 100%;
}

.hero-lg-wrap {
  height: $heroLargeHeight;

  .hero {
    height: 620px;
    position: relative;
    padding-top: 180px;
  }
}

.hero-middle-wrap {
  display: table;
  width: 100%;
  height: $heroLargeHeight;

  .hero {
    height: 620px;
    display: table-cell;
    vertical-align: middle;
  }
}

.hero-search-wrap {
  display: table;
  width: 100%;
  height: 450px;

  .hero {
    height: 450px;
    display: table-cell;
    vertical-align: middle;
  }
}

.tour-2-wrap {
  display: table;
  width: 100%;
  height: $heroLargeHeight;

  .hero {
    height: 620px;
    display: table-cell;
    vertical-align: middle;
  }
}

#search-spotlight {
  background: linear-gradient(50deg, rgba(0, 0, 0, 0.74) 0, rgba(255, 255, 255, 0.1));
  color: $color-white;

  .hero-lg {
    //background: linear-gradient(50deg,#060101 0,rgba(245, 144, 72, 0.43) 100%);
    background: linear-gradient(50deg, rgba(0, 0, 0, 0.74) 0, rgba(255, 255, 255, 0.1));
    opacity: 0.85;
  }
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
}

.valign-middle {
  vertical-align: middle;
  display: table-cell;
}

.topchart-unit h3 {
  margin-top: 0px;
  margin-bottom: 45px;
  font-size: 36px !important;
  text-transform: uppercase;
  color: #60676b;
  margin-bottom: 30px !important;
}

.topchart-unit p {
  font-family: 'Raleway';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-right: 45px;
  margin-bottom: 30px !important;
  padding-right: 75px;
}

.pricing-page {
  margin-top: 120px;
}

.pricing-page h2 {
  text-align: center;
  color: $color-dark-gray;
  margin-bottom: 60px;
}

.price-features li {
  padding: 10px 30px;
  font-size: 14px;
  overflow: hidden;
  color: $color-dark-gray;
  border-bottom: 1px solid #f3f3f4;
}

.price_card .name {
  display: block;
  font-weight: 500;
  font-size: 32px;
  padding: 30px 30px 10px;
  color: $color-dark-blue;
  text-align: left;
  font-family: $font-Bebas-Neue-Regular;
  width: 100%;
  text-transform: uppercase;
}

.price_card .info {
  color: $color-dark-gray;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  padding: 0px 30px;
  min-height: 104px;
}

.price-features li .block-teste {
  padding-left: 15px;
  cursor: help;
  text-align: right;
  float: right;
}

.question {
  padding: 20px 0px 0px;
  border-bottom: 1px solid rgba(176, 190, 197, 0.21);
}

.question h4 {
  font-size: 16px;
  font-weight: 600;
}

.question h4 a {
  color: #48535b;
  display: block;
  position: relative;
  cursor: pointer;

  &:focus {
    text-decoration: none;
  }
}

.question-link::before {
  content: "\f110";
  border: none;
  font-family: LineAwesome;
  width: auto;
  height: auto;
  float: left;
  display: block;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  color: rgba(96, 125, 139, 0.61);
  margin-right: 10px;
}

.spotlight {
  color: $color-white;
  display: block;
  width: 100%;
  height: $heroLargeHeight;

  .spotlight-content {
    height: $heroLargeHeight;
    position: relative;
    padding-top: 180px;
  }

  .featured-user {
    position: absolute;
    bottom: 30px;
    width: 275px;
    right: 80px;
    font-size: 13px;
    font-style: italic;
    text-align: right;
  }
}

#home {

  .section-features {
    background: url('../img/spotlight_bg.jpg');
    background-size: cover;
    background-position: center center;

    .thumb-wrap {
      text-align: center;
    }

    img.thumb-phones {
      max-width: 66%;
      margin-top: 36px;
    }

    img.thumb-profile {
      max-width: 55%;
      margin: 45px 0px 45px 85px;
    }

    img.thumb-full-profile {
      max-width: 55%;
      margin: 45px 0px 45px 85px;
    }

    img.thumb-insights {
      max-width: 65.2%;
      margin: 45px 75px 0px;
    }

    h4 {
      margin-top: 120px;
    }

    p {
      margin: 25px 30px 30px 0px;
    }

    @media only screen and (max-width: 480px) {

      text-align: center;
      padding: 45px 0px;

      h4 {
        margin-top: 0px;
        padding: 0 15px;
        margin-bottom: 30px;
        font-size: 28px;
        text-align: center;
      }

      img.thumb-phones {
        max-width: 60%;
        margin-top: 0px;
      }

      img.thumb-full-profile, img.thumb-profile {
        max-width: 65%;
        margin: 0px;
      }

      img.thumb-insights {
        margin: 0px;
        max-width: 60%;
      }

      p {
        margin: 30px 15px;
      }

      .carousel-control .icon-prev,
      .carousel-control .icon-next,
      .carousel-control .glyphicon-chevron-left,
      .carousel-control .glyphicon-chevron-right {
        position: absolute;
        top: 300px;
        z-index: 5;
        display: inline-block;
        margin-top: -10px;
      }
    }

  }

  #about {

    background: url('../img/builtwithlove_bg.jpg');
    background-size: cover;
    background-position: center center;
    text-align: center;

    h3 {
      margin-top: 0px;
      margin-bottom: 45px;
    }

    .team-wrap {

      h4 {
        margin: 0;
        font-size: 22px;
      }

      img {
        margin-bottom: 20px;
        width: 162px;
      }

      p {
        font-size: 13px;
        margin: 0px 0px 10px;
      }

      .links {
        padding: 5px;
        border: 0px !important;
        color: #ff9800;
        position: absolute;
        top: 121px;
        right: 61px;
        font-size: 28px;
      }
    }

    .connect-unit img {
      margin-bottom: 20px;
      height: 60px;
    }

    .connect-unit p {
      font-size: 15px;
      margin-bottom: 20px;
    }

    .connect-unit p a {
      color: $color-dark-gray;
    }
  }

  .section-top-charts {

    background: url('../img/spotlight_bg.jpg');
    background-size: cover;
    background-position: center center;

    img.thumb-phone {
      max-width: 73%;
      margin-top: 30px;
    }

    h3 {
      margin-top: 120px;
    }

    p {
      margin: 25px 30px 30px 0px;
    }

    @media only screen and (max-width: 480px) {
      text-align: center;
      padding: 45px 0px;

      h3 {
        margin-top: 0px;
        padding: 0 15px;
        margin-bottom: 30px;
        font-size: 28px;
        text-align: center;
      }

      img.thumb-phone {
        max-width: 60%;
        margin-top: 0px;
      }

      p {
        margin: 30px 15px;
      }
    }
  }

  .section-join {
    background: url('../img/joinus_bg.jpg');
    background-size: cover;
    background-position: center center;
    padding: 120px 0px;

    .block-join {
      p {
        padding: 15px 30px 30px;
        margin: 0px;
      }
    }

    @media only screen and (max-width: 480px) {
      padding: 45px 0px;
      text-align: center;

      .block-join:last-child {
        margin-top: 45px;
      }
    }
  }
}

h3.section-title {
  margin-top: 0px;
  margin-bottom: 60px;

  @media only screen and (max-width: 480px) {
    padding: 0 15px;
    margin-bottom: 30px;
    font-size: 28px;
  }
}

.section-faq {
  background: #FDFDFD;

  h4 {
    font-size: 24px;
    color: $color-dark-blue;
    margin: 0px 0px 20px;
  }

  @media only screen and (max-width: 480px) {
    text-align: center;
    padding: 45px 15px;

    h4 {
      font-size: 20px;
    }
  }
}

#brand-page {

  .spotlight-bg {
    background-image: radial-gradient(0 100%, #021d46 0, rgba(255, 255, 255, 0.0) 100%);
    background-image: -webkit-radial-gradient(0 100%, #021d46 0, rgba(255, 255, 255, 0.0) 100%);
  }

  .section-benefits {
    background: url('http://frontrow.slz.io/img/brands_home_bg2.jpg');
    background-size: cover;
    background-position: center center;
    text-align: center;
    padding-top: 120px;
    min-height: 450px;

    @media only screen and (max-width: 480px) {
      padding: 45px 0px;

      p {
        padding: 0px 15px;
      }
    }
  }

  .section-insights {
    padding: 90px 0px;
    background: #FDFDFD;

    .text {
      padding: 0px 75px;
    }

    .chart {
      height: 200px;
    }

    @media only screen and (max-width: 480px) {
      padding: 45px 0px;

      h3.section-title {
        margin-bottom: 0px;
        text-align: center;
      }

      .text {
        padding: 0px 30px;
        text-align: center;
      }

      .chart {
        height: 300px;
      }
    }
  }

  .section-join {
    background: url('http://frontrow.slz.io/img/brands_home_bg3.jpg');
    background-size: cover;
    background-position: center center;
    text-align: center;
    padding-top: 200px;
    min-height: 620px;

    @media only screen and (max-width: 480px) {
      padding: 45px 0px;
    }
  }

  .section-avatars {
    padding: 90px 0px;

    h3.section-title {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .influencer-avatar {
      position: relative;
      display: inline-block;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 15px;

      .img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    @media only screen and (max-width: 480px) {
      padding: 45px 0px;
      text-align: center;
    }
  }

  
}

.section-questions {
    h5.question-title {
      text-transform: none;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px;
    }
    h2 {
    font-size: 32px;
    color: #171f25;
    letter-spacing: 0;
    line-height: 1.6;
    margin-bottom: 45px;
    text-transform: none;
  }
  p {
    color: #7f8e98;
  }
}


#influencer-page {

    .spotlight-content {
      h1 {
        font-size: 52px;
        color: $color-white;
        font-weight: bold;
        margin-top: 0;
        line-height: 62px;
        margin-bottom: 25px;
        text-transform: none;
      }
      p {
        font-size: 18px;
      }

      @media only screen and (max-width: 480px) {
        h1 {
          font-size: 36px;
          line-height: 46px;
          text-align: left;
          padding-left: 30px; 
          margin-bottom: 15px;
        }

        p {
          text-align: left;
        }

        .subheading {
          margin: 0px 30px;
        }

        .heading-cta {
          text-align: center;
        }
      }
    }

    .featured-user {
      position: absolute;
      bottom: 30px;
      width: 275px;
      right: 80px;
      font-size: 13px;
      font-style: italic;
      text-align: right;
    }

    .section__values {
      padding-top: 100px;
      padding-bottom: 75px;

      h2 {
        font-size: 24px;
        color: #111111;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.4;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: #444444;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 1.4;
        margin-top: 0px;
      }
    }

    .values {
      overflow: hidden;
      padding-top: 100px;
      padding-bottom: 50px;

      .values-icon {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 2px solid;
        border-color: transparent;
        background: #F6F6F6;
        margin: 0 auto;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
      }
      
      .values-description {
        margin-bottom: 50px;

        h3 {
          font-size: 22px !important;
          color: #171f25;
          text-transform: none;
          letter-spacing: 0;
          line-height: 1.4;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          color: #7f8e98;
          font-weight: 300;
          letter-spacing: 1px;
          line-height: 1.5;
          margin-top: 0;
        }

        @media only screen and (min-width: 767px) {
          margin-bottom: 75px;
        }
      }
    }

    .flex-features {
      background: #F3F3F3;
      padding-top: 0;
      padding-bottom: 50px;

      @media only screen and (min-width: 767px) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    .section__brands {
      padding-bottom: 45px;
      h2 {
        font-size: 32px;
        color: #171f25;
        // font-weight: 600;
        letter-spacing: 0;
        line-height: 1.6;
        margin-bottom: 15px;
        text-transform: none;
      }

      p {
        font-size: 16px;
        color: #7f8e98;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 1.6;
        margin-top: 0px;
      }
    }

    // .section-benefits {
    //   background: url('../img/influencers/if-section-benefits.jpg');
    //   background-size: cover;
    //   background-position: center center;
    //   text-align: center;
    //   padding-top: 90px;
    //   min-height: 450px;

    //   h3.section-title {
    //     margin-bottom: 30px;
    //   }

    //   @media only screen and (max-width: 480px) {
    //     padding: 45px 0px;

    //     p {
    //       padding: 0px 15px;
    //     }
    //   }
    // }

    .section-join {
      background: #FDFDFD url('../img/influencers/if-section-get-started.jpg');
      background-size: cover;
      background-position: center center;
      text-align: center;
      padding-top: 90px;
      min-height: 450px;
      padding-bottom: 60px;

      h3.section-title {
        margin-bottom: 30px;
      }

      @media only screen and (max-width: 480px) {
        padding: 45px 0px;
      }
    }

    .section-video {
      height: 600px;
      margin: 0;
      max-height: 600px;
      max-width: 100%;
      overflow: hidden;
      padding: 0;
      width: 100%;

      .video-slide {
        max-width: 100%;
        width: 100%;
        margin: 0;
        float: left;
        padding: 70px 0 170px 0;
        background-size: cover;
        position: absolute;
        top: 600px;
        height: 600px;

        &.active {
          top: 0;
        }

        h4 {
          max-width: 960px;
          width: 100%;
          margin: 0 auto;
        }

        h4.caption {
          color: $color-white;
          font-size: 30px;
          line-height: 46px;
          margin-bottom: 30px;
          margin-top: 100px;
          font-family: 'Raleway', sans-serif;
          font-style: italic;
          font-weight: 300;
          text-transform: none;
        }

        .sub-text {
          color: $color-white !important;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: 600;
          margin-bottom: 30px;
        }

        a img {
          cursor: pointer;
        }
      }

      #youtube-player {
        position: absolute;
        width: 100%;
        z-index: 999;
        display: none;
        background: #000;

        .close_btn {
          background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
          border-radius: 50%;
          color: #333;
          font-size: 25px;
          height: 40px;
          line-height: 1em;
          padding-top: 8px;
          position: absolute;
          right: 40px;
          text-align: center;
          top: 60px;
          width: 40px;
          z-index: 999;
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
          }
        }
      }

      @media only screen and (max-width: 480px) {

        height: 400px;
        max-height: 400px;

        .video-slide {
          padding: 0px 20px;
          top: 400px;
          height: 400px;

          h4.caption {
            font-size: 16px;
            margin-top: 75px;
            line-height: 30px;
            margin-bottom: 15px;
            font-weight: 600;
          }

          .sub-text {
            margin-bottom: 15px;
            font-size: 14px;
          }
        }

        #youtube-player {
          right: 15px;
          top: 15px;
        }
      }
    }

    .section-v-thumb {
      max-width: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 562px;

      ul#horizontal-list {
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          margin-right: 10px;
          width: 76px;
          height: 76px;
          border: 2px solid #fff;
          border-radius: 50%;
          overflow: hidden;
          filter: grayscale(80%);
          -webkit-filter: grayscale(0.8);
          cursor: pointer;

          &.active, &:hover {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0);
          }

          img {
            width: 72px;
            height: 72px;
            border-radius: 50%;
          }
        }
      }

      &.move_down {
        top: 622px;
      }

      @media only screen and (max-width: 480px) {
        top: 360px;
      }
    }

    .move_down ul#horizontal-list li {
      width: 56px !important;
      height: 56px !important;
    }

    .move_down ul#horizontal-list li img {
      width: 56px !important;
      height: 56px !important;
    }

    .section-brands {

      text-align: center;
      padding: 90px 0px;
      background: #F3F3F3;

      // p {
      //   padding: 0px 30px 60px;
      //   margin: 0px;
      // }

      .block-logo {

        text-align: center;
        margin-bottom: 45px;

        .logo-item {
          display: block;
          margin-left: auto;
          margin-right: auto;

          &.logo-gems {
            height: 45px;
          }

          &.logo-careem {
            height: 80px;
            margin-top: -18px;
          }

          &.logo-socialize {
            height: 45px;
            margin-top: 9px;
          }

          &.logo-switz {
            height: 58px;
          }

          &.logo-mb {
            height: 40px;
            margin-top: 7px;
          }

          &.logo-betty {
            height: 45px;
          }

          &.logo-bpg {
            height: 50px;
          }

          &.logo-del-monte {
            height: 50px;
          }

          &.logo-philips {
            height: 50px;
          }

          &.logo-stoli {
            height: 60px;
          }

          &.logo-smirnoff {
            height: 40px;
          }

          &.logo-splash {
            height: 45px;
          }

          @media only screen and (max-width: 480px) {
            &.logo-gems {
              height: 30px;
            }

            &.logo-careem {
              height: 50px;
              margin-left: -38px;
              margin-top: -5px;
            }

            &.logo-socialize {
              height: 30px;
              margin-left: -25px;
              margin-top: 0px;
            }

            &.logo-switz {
              height: 35px;
            }

            &.logo-mb {
              height: 30px;
              margin-top: 2px;
              margin-left: -25px;
            }

            &.logo-betty {
              height: 25px;
            }

            &.logo-bpg {
              height: 25px;
            }

            &.logo-del-monte {
              height: 35px;
              margin-left: 0px;
            }

            &.logo-philips {
              height: 30px;
              margin-top: 5px;
            }

            &.logo-stoli {
              height: 35px;
              margin-left: 0px;
            }

            &.logo-smirnoff {
              height: 20px;
              margin-left: -15px;
            }

            &.logo-splash {
              height: 25px;
              margin-left: -15px;
            }
          }
        }
      }

      @media only screen and (max-width: 480px) {
        padding: 90px 0px 60px;

        p {
          padding: 0px 15px 30px;
          margin: 0px;
        }
      }
    }

    .section__questions {
      background: #FFF;
      padding: 60px 0px 90px;
    }

    h2.section__title {
      font-size: 32px;
      color: #171f25;
      margin-bottom: 30px;
      text-transform: none;
    }

    .question__block {
      padding: 20px 0px 0px;

      .question__heading {
        h4 {
          font-size: 16px;
          text-transform: none;
          margin-bottom: 0px;
        }
      }

      .question__body {
        padding: 15px 0px 15px 25px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .section__cta {
      padding: 45px 0px;
      background: #6702d4;

      h2 {
        font-size: 32px;
        font-weight: 600;
        color: $color-white;
        margin-bottom: 10px;
        text-transform: none;
      }

      p {
        color: $color-white;
      }
    }

    @media only screen and (max-width: 480px) {
      .spotlight {
        height: 540px;
      }

      .values {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      #video-slide1 {
        background-position: 560px 0px;
      }

      .question__block {
        padding: 20px 0px 0px 15px !important;
      }

      .question__heading h4 {
        font-size: 14px !important;
      }

      .section__questions {
        padding: 45px 0px !important;
      }

      h2.section__title {
        margin-bottom: 15px;
      }

      .section__cta h2 {
        margin-bottom: 20px;
      }

      .section__cta p {
        padding: 0px 10px;
      }

    }

}

.spt-home {
  background: url('../img/home/bg-spotlight.jpg') no-repeat center 0px;
  background-size: cover;

  @media only screen and (max-width: 480px) {
    background: url('../img/home/bg-spotlight-mb.jpg') no-repeat center center;
    background-size: cover;
  }
}

.spt-brand-1 {
  background: url('../img/brands/spotlight-1.jpg') no-repeat center 0px;
  background-size: cover;

  @media only screen and (max-width: 480px) {
    background: url('../img/brands/spotlight-1-mb.jpg') no-repeat center center;
    background-size: cover;
  }
}

.spt-brand-2 {
  background: url('../img/brands/spotlight-2.jpg') no-repeat center 0px;
  background-size: cover;

  @media only screen and (max-width: 480px) {
    background: url('../img/brands/spotlight-2-mb.jpg') no-repeat center center;
    background-size: cover;
  }
}

.spt-brand-3 {
  background: url('../img/brands/spotlight-3.jpg') no-repeat center 0px;
  background-size: cover;

  @media only screen and (max-width: 480px) {
    background: url('../img/brands/spotlight-3-mb.jpg') no-repeat center center;
    background-size: cover;
  }
}

.spt-influencer-1 {
  background: url('../img/influencers/sp-inf-bg-1.jpg') no-repeat center 0px;
  background-size: cover;

  @media only screen and (max-width: 480px) {
    background: url('../img/influencers/sp-inf-bg-1-mb.jpg') no-repeat center 0px;
    background-size: cover;
  }
}

.spt-influencer-2 {
  background: url('../img/influencers/sp-inf-bg-2.jpg') no-repeat center 0px;
  background-size: cover;

  @media only screen and (max-width: 480px) {
    background: url('../img/influencers/sp-inf-bg-2-mb.jpg') no-repeat center 0px;
    background-size: cover;
  }
}

.spt-influencer-3 {
  background: url('../img/influencers/sp-inf-bg-3.jpg') no-repeat center 0px;
  background-size: cover;

  @media only screen and (max-width: 480px) {
    background: url("../img/influencers/sp-inf-bg-3.jpg") no-repeat -800px 0px;
    background-size: cover;
  }
}

