/* REFACTOR */
@media (min-width: 768px) and (max-width: 1200px) {

  .main-menu ul li.megamenu .menu-wrapper {width:940px;}
}

@media (max-width: 1024px){

}

@media (max-width: 991px) {

  .sticky nav {
    margin-top:3px;
  }

  nav{
    margin-top:15px;
  }

  header#plain{
    padding-bottom:10px;
  }

  header#plain.sticky{
    padding-bottom:0;
  }

  .main-menu ul li.megamenu .menu-wrapper {
    width:auto; margin-top:20px;
  }
}

@media (max-width: 768px) {

}

/* Mobile Landscape */
@media (max-width: 767px) {

  #modal-login .logo-wrapper img {
    width: 60% !important;
  }

  #drawing-wrapper {
    display: none;
  }

  .timeline-unit {
     height: auto;
  }

  .join-inner .join-block-right {
    border-right: none;
  }

  .topchart-unit .topchart-right {
    text-align: center;
  }

  .cmn-toggle-switch {
    margin-top: 10px;
  }

  .margin_60 {
    padding-top:30px; padding-bottom:30px;
  }

  .margin_30 {
    margin-top:15px; margin-bottom:15px;
  }

  #hero .hero-title {
    padding: 0px 15px;
  }

  .hero-title p {
    padding: 0px 15px;
  }
}

@media (max-width: 480px) {

}

/* Mobile Portrait */
@media only screen and (max-width: 320px) {

}
/* END REFACTOR */

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

  .search .filters{
    display: none;
  }

  .search_filter{
    display: none;
  }

  .searched_area {
    background: #FFF;
    //padding-top: 75px;
    //padding-bottom: 50px;
    //padding-left: 15px;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {

  .main-menu {
    display: none;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

  // nav.navbar .navbar-brand {
  //   padding: 11px 0px 15px;
  // }

  nav.navbar li a {
    padding: 15px 20px 15px 35px;
  }

  .toggle-menu {
    position: absolute;
    right: 15px;
    border: 1px solid #FFF;
    padding: 5px 10px 3px;
    font-size: 12px;
    color: #FFF;
    top: 17px;
  }

  .main-logo-wrapper {
    padding: 13px 0px;
  }

  .spotlight .spotlight-content {
    padding-top: 180px;

    // p {
    //   margin: 0 15px 30px 15px;
    //   text-align: center;
    // }

    .spotlight-btn-wrap {
      text-align: center;

      .btn-spotlight:last-child {
        margin-right: 0px;
      }

      .btn-spotlight-brand {
        width: 140px;
        padding: 8px 5px;
      }
      .btn-spotlight-brand:last-child {
        margin-right: 0px;
      }
    }

    .featured-user {
      bottom: 10px;
      right: 10px;
      font-size: 12px;
    }
  }

  h1 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center;
  }

  .col-xs-0 {
    width: 0;
    display: none;
  }

  .left_center {
    text-align: center;
  }

  .display_desktop {
    display: none;
  }

  .display_mobile {
    display: block;
  }

  .right_left {
    text-align: left;
  }

  .result-count {
    font-size: 16px !important;
    padding-top: 0;
  }

  .total_credits {
    font-size: 16px !important;
    padding-top: 0;
    padding-bottom: 15px;
  }

  #search-spotlight {
    display: none;
  }

  .searched_area {
    background: #FFF;
    padding-top: 75px;
    padding-bottom: 50px;
    padding-left: 15px;
  }

  footer {
    padding: 30px 0px;
    position: absolute;
    bottom: 0;
    width: 100%;

    .block-links {
      min-height: 150px;
    }

    .social__wrap {
      padding-top: 0px;
    }
  }

  #filter_mobile {
    display: block;
  }

  #main-nav-wrapper {
    // -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
    // -moz-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
    // box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
    // background-color: rgba(6, 14, 49, 0.85);
    padding-bottom: 0;
  }

  #main-nav-wrapper.sticky {
    // -webkit-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
    // -moz-box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
    // box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.41);
    // background-color: rgba(6, 14, 49, 0.85);

  background-color: rgba(255, 255, 255, 0.85)!important;
    padding-bottom: 0;

    @media (max-width: 480px) {

      background-color: rgba(255, 255, 255, 0.85)!important;
    }
  }

  nav.navbar .navbar-collapse {
    // background-color: rgba(255, 255, 255, 0.85)!important;
    margin-left: -15%;
    width: 130%;
    margin-top: 6px;
    border-top: 0px solid;
    box-shadow: none;
  }

  nav {
    margin-top: 3px;
  }

  .search {
    .infl-container {
      margin-bottom: 0;
    }
  }

  .filter_btns{
    button{
      padding: 4px;
      margin-right: 0;
      width: 100px;
    }
  }

  .search .influencer-result .influencer-container .influencer-name a {
    font-weight: 400 !important;
  }

  .search .influencer-result .influencer-container .influencer-stats .influencer-social-block, .search .influencer-result .influencer-container .locations {
    font-size: 11px;
    color: #878a8c;
  }

  .search .influencer-result .influencer-container .influencer-stats a {
    color: #878a8c;
  }

  .search .influencer-result .influencer-container .influencer-stats .influencer-social-block i {
    line-height: 18px;
  }
}
