// @font-face {
//     font-family: 'Bebas Neue Regular';
//     src: url('fonts/BebasNeueRegular.ttf') format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Bebas Neue Bold';
//     src: url('fonts/BebasNeueBold.ttf') format('truetype');
//     font-weight: 700;
//     font-style: normal;
//  }
// @font-face {
//     font-family: 'Bebas Neue Light';
//     src: url('/fonts/BebasNeueLight.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Bebas Neue Thin';
//     src: url('/fonts/BebasNeueThin.ttf') format('truetype');
//     font-weight: 100;
// }

// @font-face {
//     font-family: "frontrow";
//     src: url("fonts/frontrow.eot");
//     src: url("fonts/frontrow.eot?#iefix") format("embedded-opentype"), url("fonts/frontrow.woff") format("woff"), url("fonts/frontrow.ttf") format("truetype"), url("fonts/frontrow.svg#frontrow") format("svg");
//     font-weight: normal;
//     font-style: normal;
// }


@font-face {
    font-family: 'Test';
    src: url('../fonts/WAS/GT_Walsheim.woff') format('woff');
    // font-weight: 400;
    font-style: normal;
}