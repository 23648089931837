.btn {
  font-size: 14px;
  padding: 8px 25px;
  font-weight: 600;
  border-radius: 0px;
  border-radius: 0px;
  text-transform: uppercase !important;
}

.btn-grey {
    border-color: #60676b;
    color: #60676b;
}

.btn-outline {
  border: 1px solid;
}

.btn-md {
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  padding: 0px 25px;
}

.btn-sm {
  font-size: 12px;
  padding: 2px 7px;
}

.btn-intro {
    border: 1px solid #FFF;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0px;
    padding: 8px 25px;
    text-transform: uppercase;
    width: 150px;
    margin-bottom: 15px;
    margin-right: 15px;
}

.btn-intro:hover,
.btn-intro.hover,
.btn-intro:focus,
.btn-intro.focus,
.btn-intro:active,
.btn-intro.active {
  color: #313a4c;
  background: #FFFFFF;
}

.btn-spotlight {
  width: 150px;
  margin-right: 15px;
}

.btn-spotlight-brand {
  width: 200px;
  margin-right: 15px;
}

.btn-outline-white {
  border: 1px solid $color-white;
  color: $color-white;
  background: transparent;

  &:hover {
    background: $color-white;
    color: $color-dark-gray;
  }
}


.btn-outline-orange-home {
  border: 1px solid #ff9800 !important;
  color: #ff9800 !important;
  background: $color-white;
  &:hover {
    background: #ff9800;
    color: $color-white!important;
  }
}


.btn-outline-red-home {
  border: 1px solid #E53E30 !important;
  color: #E53E30 !important;
  background: $color-white;
  &:hover {
    background: #E53E30;
    color: $color-white!important;
  }
}



.btn-outline-orange {
  border: 1px solid #ff9800 !important;
  color: #ff9800 !important;
  background: $color-white;
}

.btn-outline-gray {
  border: 1px solid $color-dark-gray;
  color: $color-dark-gray;
  background: transparent;

  &:hover {
    background: $color-dark-gray;
    color: $color-white;
  }
}

.btn-orange {
  border: 1px solid #ff9800 !important;
  background: #ff9800 !important;
  color: #FFF;
}


.btn-red {
  border: 1px solid #E53E30 !important;
  background: #E53E30 !important;
  color: #F6F6F6;

  &:hover{
    color: #F6F6F6!important;
  }
}


.btn-pink {
  border: 1px solid $color-pink !important;
  background: $color-pink !important;
  color: #FFF;
}

.btn-pink:hover,
.btn-pink.hover,
.btn-pink:focus,
.btn-pink.focus,
.btn-pink:active,
.btn-pink.active {
  color: #FFFFFF !important;
  background: $color-pink !important;
  border: 1px solid $color-pink !important;
}


.btn-full {
  width: 100% !important;
}

.btn-orange:hover,
.btn-orange.hover,
.btn-orange:focus,
.btn-orange.focus,
.btn-orange:active,
.btn-orange.active {
  color: #FFFFFF !important;
  background: #ff9800 !important;
}

.btn-instagram {
  background: #e4405f;
  color: #FFF;
  border: 1px solid #e4405f;

  &:hover {
    color: #FFF;
    background: #ff4b6d;
    border: 1px solid #ff4b6d;
  }
}

.btn-facebook {
  border: 1px solid #3b5998;
  background: #3b5998;
  color: #FFF;

  &:hover {
    color: #FFF;
  }
}

.btn-twitter {
  border: 1px solid #1da1f2;
  background: #1da1f2;
  color: #FFF;

  &:hover {
    color: #FFF;
  }
}

.btn-youtube {
  border: 1px solid #cd201f;
  background: #cd201f;
  color: #FFF;

  &:hover {
    color: #FFF;
  }
}

.btn-create-page {
  padding: 4px 8px !important;
  margin-right: 10px;
  margin-top: 15px;
  border-radius: 3px;
  color: #FFF;
  border: 1px solid #5cb85c !important;

  &:active, &:focus {
    color: #FFF !important;
  }
  &:hover {
    color: #FFF !important;
    background: #5cb85c !important;
  }
}

.btn-purple {
  border: 1px solid $color-purple;
  color: $color-white;
  background: $color-purple;

  &:hover {
    background: $color-purple-hover;
    color: $color-white;
    border: 1px solid $color-purple-hover;
  }
}




.btn-purple-white-text {
  border: 1px solid $color-purple;
  color: $color-white;
  background: transparent;

  &:hover {
    background: $color-purple-hover;
    color: $color-white;
    border: 1px solid $color-purple-hover;
  }
}
.btn-purple-purple-text {
  border: 1px solid $color-purple;
  color: $color-purple;
  background: transparent;

  &:hover {
    background: $color-purple-hover;
    color: $color-white;
    border: 1px solid $color-purple-hover;
  }
}