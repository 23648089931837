.ks-influencers-directory {

    .search {

        a {
            text-decoration: none;
        }

        a.upgrade {
            text-decoration: none;
            color: $color-gray !important;

            &:after {
                text-align: center;
            }
        }

        @media only screen and (max-width: 480px) {
            margin-top: 0px;
        }

        background-color: #fff;
        padding-top: 0px;
        padding-bottom: 0px;

        .filters-wrap {
            background-color: #f4f4f4;
            padding-top: 0px;
            padding-bottom: 50px;
            padding-right: 30px;
        }

        .filters {
            background: #F4F4F4;
            padding: 40px 15px 40px 30px;

            ul {
                list-style: none;
                margin: 0px;
                padding: 0px;

                li {
                    margin-bottom: 5px;

                    .check {
                        margin-right: 5px;
                    }
                }
            }

            legend {
                font-size: 16px;
                text-transform: uppercase;
                line-height: 26px;
                font-weight: 600;
                border: 0px;
                margin-bottom: 5px;
                margin-top: 15px;
                color: $color-dark-gray;
            }

            label {
                margin-left: 5px;
            }

            .bootstrap-tagsinput {
                box-shadow: none;
                padding: 3px 7px 6px;
                border: 1px solid #e3e3e3;

                .label-info {
                    background-color: #5fbeaa !important;
                    display: inline-block;
                    padding: 5px;
                }
            }

        }

        .pro-filter {
            font-size: 12px;
            background: #ee3694;
            color: #FFF;
            font-weight: bold;
            margin-left: 5px;
            padding: 7px 5px;
            line-height: 3px;
            margin-top: 4px;
            border: 1px solid #ee3694;
            position: absolute;
            cursor: pointer;
        }

        .result-wrap {
            background: $color-white;
            padding: 50px 15px 50px 30px;
        }

        .result-count {
            font-size: 18px;
            padding-bottom: 15px;
            color: #596377;
            font-weight: 300;

            @media (max-width: 768px) {
                text-align: center;
            }

            i {
                margin: 0 5px;
            }

            strong {
                color: $color-pink;
            }
        }

        .influencer-result {
            .influencer-container {
                margin-bottom: 40px;

                @media only screen and (max-width: 767px) {
                    margin-bottom: 15px;
                }

                a {
                    color: $color-dark-gray;
                }

                .influencer-name {
                    position: relative;
                    padding: 10px 0px 0px 0px;
                    font-size: 16px;
                    font-weight: 600;

                    @media only screen and (max-width: 480px) {
                        a {
                            font-size: 14px;
                        }
                    }
                }

                .influencer-alias {
                    font-size: 14px;
                }

                .influencer-stats {
                    font-size: 14px;
                    padding: 0px 0px 10px;
                    margin-right: 5px;

                    i {
                        font-size: 12px;
                    }
                }
            }
        }

        .grid-header {
            line-height: 40px;
            margin-bottom: 30px;
            overflow: hidden;
        }

        .social-block {

            overflow: hidden;
            width: 50%;
            float: left;
            padding-top: 15px;

            .icon {
                float: left;
            }

            .count {
                padding-left: 35px;
                font-family: 'PT Sans', sans-serif;
                margin-top: 0px;
                margin-left: 3px;
                font-size: 12px;
            }
        }

        .social-icon {
            float: left;
            margin-right: 8px;
        }

        .social-icons-new i {
            color: #fff;
            width: 26px;
            border-radius: 50%;
            font-size: 14px;
            height: 26px;
            text-align: center;
            line-height: 26px;
            margin-top: 10px;

            @media only screen and (max-width: 480px) {
                margin-top: 7px;
            }
        }

        .selectize-control.multi .selectize-input > div {
            cursor: pointer;
            margin: 0 3px 3px 0;
            padding: 2px 6px;
            background: $color-gray;
            font-weight: 500;
            color: $color-white;
            border: 1px solid $color-gray;
        }

        .selectize-control.multi .selectize-input [data-value] {
            text-shadow: none;
            border-radius: 0px;
            background-color: $color-gray;
            background-image: none;
            filter: none;
            box-shadow: none;
        }

        .selectize-control.plugin-remove_button [data-value] .remove {
            border-left: 1px solid $color-gray;
        }
    }

}

.ks-filters-slide-block {
    position: fixed;
    top: 60px;
    right: -295px;
    width: 295px;
    height: 100%;
    background: #f4f4f4;
    border-left: solid 1px rgba(57, 81, 155, 0.2);
    z-index: 2;
    -webkit-transition: right .4s ease;
    transition: right .4s ease;
}

@media (min-width: 992px) {
    .ks-filters-slide-control {
        display: none;
    }
}

.ks-filters-slide-block.ks-open {
    right: 0;
}

.ks-filters-slide-block > .ks-filters-slide-control {
    cursor: pointer;
    position: absolute;
    font-size: 21px;
    padding: 8px 10px;
    top: 2%;
    background: #f4f4f4;
    border: solid 1px rgba(57, 81, 155, 0.2);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    left: -42px;
    border-right: none;
}

.ks-filters-slide-block > .ks-filters-slide-control:hover {
    text-decoration: none;
}

.ks-filters-slide-block > .ks-filters-slide-control > .ks-icon {
    color: $color-dark-gray;
}

.ks-filters-slide-block > .ks-filters-slide-control > .ks-icon:hover {
    color: #ee3694;
}

.ks-filters-slide-block > .ks-filters-floated {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding: 30px;
}

.ks-filters-slide-block > .ks-filters-floated > .ks-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.ks-filters-slide-block > .ks-filters-floated > .ks-header > .ks-text {
    font-size: 16px;
    font-weight: 600;
    color: #ee3694;
}

.ks-filters-slide-close-control {
    cursor: pointer;
    float: right;
}

.ks-filters-slide-block > .ks-filters-floated > .ks-header .ks-filters-slide-close-control > .ks-icon {
    font-size: 14px;
    color: rgba(58, 82, 155, 0.6);
}

.ks-filters-slide-block > .ks-filters-floated > .ks-header .ks-filters-slide-close-control > .ks-icon:hover {
    color: rgba(58, 82, 155, 0.8);
}

html.ks-filters-opened body {
    overflow: hidden;
}

@media (max-width: 1024px) {
    .ks-filters-floated::-webkit-scrollbar {
        width: 3px;
    }
}


@media (max-width: 767px) {
    div#influencerListing {
        margin-right: -7.5px;
        margin-left: -7.5px;

        .influencer-result {
            padding: 7.5px;
        }
    }
}

@media (max-width: 1024px) {
    .ks-filters-floated::-webkit-scrollbar-thumb {
        background: #B8B8B8;
    }
}

.ks-filters-slide-block > .ks-filters-floated > .ks-filters legend {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 24px;
    font-weight: 600;
    border: 0px;
    margin-bottom: 5px;
    margin-top: 15px;
    color: #444F58;
}

.ks-filters-slide-block > .ks-filters-floated > .ks-filters ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.ks-filters-slide-block > .ks-filters-floated > .ks-filters ul li {
    margin-bottom: 5px;
}

.owl-carousel {
    margin: 0;

    .owl-dots {
        display: block;
        text-align: center;

        .owl-dot {
            background-color: #f89200;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin: 0 3px;
            opacity: 0.3;

            &.active {
                opacity: 1;
            }
        }
    }

    .influencer-result {
        padding: 0;
    }
}

.pro-filter {
    font-size: 12px;
    background: #ee3694;
    color: #FFF;
    font-weight: bold;
    margin-left: 5px;
    padding: 0px 5px;
    line-height: 3px;
    margin-top: 4px;
    border: 1px solid #ee3694;
    cursor: pointer;
}
