.signup-wrap {
    background: url('../img/signup/get-started-wood-bg.jpg');
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 120px 0px;
}

.signup-start {

    h2 {
        color: $color-white;
        margin-bottom: 45px;
        text-align: center;
    }

    .block-sel-profile {
        background: $color-white;
        padding: 30px 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, .5);

        p {
            margin: 15px 0px;
        }
    }

    @media only screen and (max-width: 480px) {

        h2 {
            font-size: 32px;
        }

        .block-sel-profile {
            margin-bottom: 45px;
        }
    }
}

.signup-form {
    background: $color-white;
    padding: 30px 30px;

    .required {
        font-size: 18px;
        color: #15a7e2;
    }

    .form-input {
        display: block;
        width: 100%;
        padding: 0 15px;
        height: 36px;
        background-color: rgba(252, 252, 252, 0.45);
        border: 1px solid #dde5ed;
        border-radius: 0px;
        -webkit-transition: border-color ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s;
        transition: border-color ease-in-out .15s;

        &:focus {
            border: 1px solid #2e2e7f;
            outline: none;
        }
    }

    @media only screen and (max-width: 480px) {
        h3 {
            font-size: 26px;
        }
    }
}

.signup p {
    margin: 20px 0px;
}

span.required {
    font-size: 18px;
    color: #15a7e2;
}

.form-submit-wrap {
    margin: 30px 0px 0px;
}

p.disclosure {
    font-size: 14px;
    line-height: 24px;
    padding: 0px 30px;
}

.influencer__signup {
    padding: 90px 0px;
    background: #F4F4F4;
}

// .influencer__overlay {
//     padding: 60px 0px;
//     background: rgba(0, 0, 0, 0.55);
// }

.signup-header {
    padding: 0px 30px 20px;

    p {
       font-size: 22px;
    }
}

h1.heading {
    font-size: 32px;
    color: $color-dark-gray;
    font-weight: bold;
    margin-top: 30px;
    padding: 0px 45px;
    margin-bottom: 30px;
    line-height: 26px;
}

span.subheading {
    color: $color-dark-gray;
    display: block;
    margin-top: 15px;
    font-size: 18px;
    line-height: 18px;
    font-weight: normal;
    text-transform: none;
}

.signup__form {
    background: #FFFFFF;
    padding: 45px;
    border-radius: 3px;
}

.form__label {
    font-weight: 600;
    color: #444F58;
    font-size: 14px;
}

.form__label--forgot {
    font-weight: 400;
    color: #444F58;
    font-size: 14px;
    text-align: right;
    margin-top: 5px;
}

.form__group {
    position: relative;
    margin-bottom: 23px;

    .selectize-input {
        border: 1px solid #D4D9E2;
        box-shadow: none;
        border-radius: 0px;
        min-height: 50px;
    }
}











.form__input {
    border: 1px solid #D4D9E2;
    border-radius: 0px;
    padding: 15px;
    font-size: 14px;
    color: #596377;
    outline: 0;
    -webkit-transition: border-color .2s ease;
    transition: border-color .2s ease;
}

.form__error {
    font-size: 14px;
    font-weight: 600;
    color: #E73737;
    letter-spacing: 0;
    margin-bottom: 24px;
    text-align: center;
}

.form__input, .form__select {
    height: 50px;
    display: block;
    width: 100%;
}

a.btn__channel:hover {
    color: #fb6d9d;
}

a.btn__channel__active:hover {
    color: #FFF !important;
}

.btn__channel {
    border: 1px solid #fb6d9d;
    color: #fb6d9d;
    height: 50px;
}

.btn__channel:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.btn__channel:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.btn__channel__active {
    background: #fb6d9d;
    color: #FFF;
}

.form__group__submit {
    text-align: center;
    margin-top: 25px;
}

.btn__signup {
    border: 1px solid #7266ba !important;
    // background: #7266ba !important;
    background: white !important;
    color: #7266ba!important;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    border-radius: 3px;
}

.btn__signup:hover,
.btn__signup:active,
.btn__signup:focus {
    border: 1px solid #675da9 !important;
    background: #675da9 !important;
    color: #FFF;
}

.form__legal {
    padding: 30px 15px 0px;
    color: #8f8f8f;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

    .influencer__signup {
        padding: 90px 0px 60px;
    }

    .signup-header {
        padding-bottom: 0px;
    }

    h1.heading {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0px;
        padding: 0px 15px;
        margin-bottom: 15px;
        line-height: 26px;

    }
    span.subheading {
        display: block;
        margin-top: 10px;
        font-size: 14px;
        padding: 0px 30px;
        line-height: 18px;
        font-weight: normal;
        text-transform: none;
    }

    .signup__form {
        background: transparent;
        padding: 25px 25px 0px;
        border-radius: 3px;
    }

    .form__group {
        margin-bottom: 10px;
    }

    .form__input, .form__select {
        height: 42px;
        // border: 1px solid #ffffff;
    }

    .btn__channel {
        border: 2px solid #fb6d9d;
        height: 40px;
    }

    .form__group__submit {
        text-align: center;
        margin-top: 10px;
    }

    .form__legal {
        padding: 15px;
        margin-bottom: 0px;
        color: $color-gray;
    }
}
