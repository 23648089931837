.footer{
    a{
        margin: 0 10px;
    }
}

.c-pointer {
    cursor: pointer;
}

.badge-warning {
    background-color: #ec971f;
}

.quick-links span {
    font-size: 36px;
    line-height: 29px;
    font-weight: bold;
}

.input__phone {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid #D4D9E2;
    border-radius: 1px;

    .input__phone__code {
        background: #fff;
        font-size: 13px;
        width: 65px;
        border: 0;
    }

    .select2-container--default .select2-selection--single {
        height: 100%;
        border: 0;

        .select2-selection__rendered {
            height: 50px;
            line-height: 50px;
            font-size: 14px;
            color: #596377;
        }

        .select2-selection__arrow {
            height: 100%;
        }
    }

    .input__phone__number {
        border: 0;

        z-index: 0;

        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;

        position: relative;
        z-index: 2;
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;

        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

.selectpicker-clear {
    position: absolute;
    z-index: 10;
    right: 11px;
    background-color: #E53E30;
    top: 16px;
    font-size: 12px;
    display: none;
    cursor: pointer;
    color: #F6F6F6;

    @media (max-width: 480px) {
        top: 13px;
    }
}

.form__input.bg-grey {
    background-color: #f8f8f8;
    border: 1px solid #dedede;
}

.open > .btn-default.dropdown-toggle {
    background-color: #f8f8f8;
}

.bootstrap-select.form__select {
    border: 1px solid #dedede;
    border-radius: 3px;
    font-size: 14px;
    color: #596377;
    outline: 0;
    transition: border-color .2s ease;
    width: 100%!important;
    display: block;

    .dropdown-toggle {
        border: 0;
        padding-left: 15px;
        color: #596377 !important;
        text-transform: none !important;
        font-weight: normal;
        height: 100%;
        background-color: #f8f8f8;

        &:active,
        &:hover,
        &:focus {
            outline: none!important;
            background: #f8f8f8;
        }

        &.bs-placeholder .filter-option {
            color: #818181;
        }

        .filter-option {
            width: 92%!important;
            line-height: 33px;

            @media (max-width: 480px) {
                width: 96% !important;
                line-height: 25px;
            }
        }

        .bs-caret {
            background: #E53E30;
            color: #F6F6F6;
            padding: 2px 7px;
            border: 1px solid #E53E30;
            border-left-width: 0;
            border-radius: 0px 3px 3px 0;
            font-size: 19px;
            position: absolute;
            right: -1px;
            top: -1px;
            width: 34px;
            height: 104%;
            //display: inline-block;
            //border-left: 1px solid #dedede;
            //height: 100%;
        }
    }
}

.featured_section {
    padding: 15px 30px;
    background-color: #f5f5f5;
    margin-bottom: 25px;
    border-radius: 5px;

    .title {
        margin-bottom: 10px;
        background-color: transparent;
        color: #777;
        padding: 10px 0;
        text-transform: uppercase;
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        display: block;
    }
}

.ks-influencers-directory .search #influencerFeaturedParent .influencer-result .influencer-container {
    margin-bottom: 0;
}

.ks-influencers-directory .search .influencer-result .influencer-container a {
    display: inline-block;
}

.ks-influencers-directory .search .influencer-result .influencer-container .infl-avatar {
    position: relative;
    //overflow: hidden;

    .avatar {
        background-size: cover;
        background-color: #f8f8f8;
        width: 100%;
        padding-bottom: 100%;
        overflow: hidden;
    }

    .infl-stats-new {
        position: absolute;
        top: 0;
        width: 50px;
        height: 100%;
        text-align: right;
        padding: 10px 10px;
        right: 0;

        [data-tooltip]:after, .tooltip:after, .tooltip-top:after {
            margin-left: -600%!important;
        }

        i {
            width: 25px;
            height: 25px;
            margin-left: 3px;
            background-color: #fff;
            border-radius: 50%;
        }
    }
}

.outer_container {
    background: none;
}

.equals {
    display: flex;
    flex-wrap: wrap;
}

.searched_area .form-filter {
    margin-bottom: 10px;
}

.form__input:disabled {
    background-color: #f4f4f4;
    border-color: #f1f1f1;
}

.with__icon {
    .form__input {
        padding-left: 55px;
    }

    .icon {
        font-size: 12px;
        background: #ee3694;
        color: #FFF;
        font-weight: bold;
        padding: 3px 4px;
        position: absolute;
        cursor: pointer;
        border-radius: 2px;
        top: 11px;
        line-height: 1em;
        text-align: center;
        left: 6px;
    }
}

.with__submit .form__input {
    padding-right: 47px;
}

.in-submit-button {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    background: #E53E30;
    color: #F6F6F6;
    padding: 2px 7px;
    border: 1px solid #E53E30;
    border-left-width: 0;
    border-radius: 0px 3px 3px 0;
    font-size: 19px;

    &:focus,
    &:active {
        outline: none;
    }
}

.trending-icon {
    color: #19b51c;
    font-size: 12px;
    margin: 0 3px;
}

.one-line-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.infl-banner {
    // background: url('/img/influencers/background2.jpg') no-repeat center top;
    background: url('/assets/web/img/home_banner_2.jpg') no-repeat center ;
    background-size: cover;

    .banner-content {

        display: flex;
        justify-content: center;

        color: #fff;
        text-align: center;
        height: 90vh;
        position: relative;
        padding: 170px 20px 20px;
        background: rgba(0,0,0,.7);
        background: -webkit-gradient(left top,right bottom,color-stop(0,rgba(0,0,0,.7)),color-stop(100%,rgba(255,255,255,0)));
        background: linear-gradient(0,rgba(0,0,0,.7) 0,rgba(255,255,255,0) 80%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=1 );

        h1 {
            font-size: 45px;
        }

        p {
            font-size: 23px;
            line-height: 29px;
            margin: 0;
        }

        a {
            margin: 30px 15px 0;
            font-size: 20px;
        }


        // @media (max-width: 768px) {
        //     display: flex;
        //     .banner-content-box{
        //         margin: auto;
        //     }
        // }


        #country_counter{
            margin-right: 10px;
        }

        .banner-content-box{  
            position: absolute;
            bottom: 5%;
        }


      
    }




    .index_dropdown_location{
        justify-content: center; 
        margin-left: 10px; 
        position: relative;
        border-radius: 0!important;

        // margin: 10px 0;

        .location_title_container{

            cursor: pointer;

            &:hover, &:focus, &:active{
                #location_title{
                    color: #E53E30;
                }
                svg{
                    fill: #E53E30;
                }
            }

            #location_title{
                transition: 0.3s all ease-in-out;
                width: 100%; 
            }

            svg{
                transition: 0.3s all ease-in-out;
                fill: white;
                width: 20px;
                height: 35px;
                position: absolute;
                bottom: 1px;
                margin-left: 10px;

                &:hover, &:focus, &:active{
                        fill: #E53E30;
                }

                @media (max-width: 768px) {
                    margin-left: 5px;
                    width: 13px;
                    height: 23px;
                }
            }
        }

        
        #locationCollapse{
            position: absolute; 
            width: 100%;
            text-align: left;
            border-radius: 5px!important;
            z-index: 998!important;
            ul{
                min-width: 200px;
                max-width: 200px;
                width: 100%; 
                font-size: 10px; 
                max-height: 300px;
                height: 0px;
                overflow-y: hidden;
                border-radius: 5px!important;
                margin: 0 auto;
                transition: 0.3s all ease-in-out;
                li{
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                    color: black; 
                    text-transform: capitalize;
                    border: none;

                    transition: 0.3s all ease-in-out;

                    &:hover{
                        color: #E53E30;
                    }
                }
            }
        }
    }


}

.icon-instagram,
.icon-youtube,
.icon-snapchat,
.icon-twitter,
.icon-facebook {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
    content: "\f082";
}

.icon-twitter:before {
    content: "\f099";
}

.icon-instagram:before {
    content: "\f16d";
}

.icon-youtube:before {
    content: "\f16a";
}

.icon-snapchat:before {
    content: "\f2ab";
}

.icon-trending,
.icon-responsive,
.icon-send {
    width: 100px;
    height: 100px;
    background: transparent no-repeat scroll center;
    background-size: 100%;
    display: inline-block;
}

.icon-send {
    background-image: url('/assets/web/img/icon-email-red.png');
}


.icon-responsive {
    background-image: url('/assets/web/img/icon-responsive-pink.png');
}

.icon-trending {
    background-image: url('/assets/web/img/icon-trending-pink.png');
}

.modal-header .close {
    font-size: 28px;
}

.tf-initial {
    text-transform: initial;
}

.table-bg {
    background: url(/assets/web/img/signup/get-started-wood-bg.jpg) no-repeat center center;
    background-size: cover;
    color: #fff;
}

.no-results-found {
    color: #596377;
}

#js-page-list {
    display: flex;
    flex-wrap: wrap;

    .col-md-4 {
        margin-bottom: 60px!important;

        .brand-entry {
            height: 100%;
        }
    }
}

@media (max-width: 768px) {
    .infl-banner {
        .banner-content {
            padding-top: 110px;

            h1 {
                font-size: 31px;
                font-weight: 700;
            }

            p {
                line-height: 24px;
                font-size: 18px;
                padding: 0 15px 20px;
            }

            a {
                // width: 150px;
                font-size: 14px;
                margin: 10px 15px 0;
            }
        }
    }

    .searched_area {
        padding-top: 30px;
        padding-bottom: 0;
    }
}
@media (max-width: 992px) {
    .featured_section {
        padding: 5px 15px;
    }
}

@media (max-width: 1200px) {

}
