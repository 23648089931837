.topcharts {
  background: url('../img/topchart_mobilebg.png');
  background-size: cover;
  background-position: center center;
  margin-top: 60px;

  .carousel-fade .carousel-control {
    z-index: 2;
    opacity: 1.0;
    margin: -60px;
  }
}

.pinkMain {
  color: $color-pink !important;
  font-size: 40px !important;
}

.heading-title {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 30px 0 !important;
  text-align: center;
  margin: 0px;
  line-height: 30px;
  text-transform: none;
}

.countryTitle {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 30px;

  .flag {
    width: 45px;
    height: 45px;
    display: inline-block;
    background-size: cover;
    margin-right: 25px;
    margin-bottom: -15px;
    &.ae {
      background-image: url('../img/uae-flag-round.png');
    }

    &.sa {
      background-image: url('../img/ksa-flag-round.png');
    }

    &.kw {
      background-image: url('../img/kuwait-flag-round.png');
    }
  }

  .interestIcon {
    background-image: radial-gradient(0 100%, #cb1a8c 0, #ec9d97 100%);
    background-image: -webkit-radial-gradient(0 100%, #cb1a8c 0, #ec9d97 100%);
    color: white;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 25px;
    margin-bottom: -15px;
    &:before {
      margin-top: 10px;
      margin-left: 0px;
      display: block;
      font-size: 22px;
    }
  }

}

.topList {
  font-size: 16px;
  list-style-position: inside;
  margin-top: 26px;
  counter-reset: item;
  list-style-type: none;
  min-height: 275px;
  max-height: 340px;
  overflow: hidden;

  li {
    margin-bottom: 15px;
    padding-left: 40px;
    width: 100%;
    display: table;
    .img, span, .number {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .number {
    width: 25px;
  }
  .img {
    width: 55px;
    height: 55px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    border-radius: 50%;
    display: inline-block;
  }
  span {
    display: inline-block;
    height: 55px;
    vertical-align: middle;
    padding-left: 10px;
  }
}

.moreList {
  display: block;
  width: 100%;
  text-align: center;
  clear: both;
}
