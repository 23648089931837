#gridInfluencers{
  .block_text{
    margin-top: 60px;
    .tour_2_text{
      padding: 20px 120px 30px 15px;
    }
  }
}

#brandBenefit{
  .hero-sm{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

#brandQuestions{
  .hero-sm{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

