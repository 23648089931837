body {
    background: $color-white;
    font-size: 16px;
    font-family: $font-primary;
    font-weight: 400;
    color: $color-dark-gray;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0px;
    text-transform: uppercase;
}

h1 {
    font-size: 40px;
    color: $color-white;
    margin-top: 0px;
    margin-bottom: 25px;
}

h2 {
    font-size: 44px;
}

h3 {
    font-size: 36px;
}

h4 {
    font-size: 28px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

p {
    margin: 0 0 30px 0;
    line-height: 26px;
}

a.link-underline {
    text-decoration: underline !important;
}

label {
    color: $color-dark-gray;
    font-size: 14px;
    font-weight: 400;

}

label.error {
    color: $color-red;
    font-weight: 300;
}

footer {
    background: #ffffff;
    padding: 40px 0 40px;
    border-top: 1px solid #dee0e1;
    color: $color-dark-gray;
    font-size: 14px;
    background-color: #F4F4F4;

    .fr-box {
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    a, a:hover, a:active {
        color: $color-dark-gray;
    }

    .logo {
        margin-top: 28px;
        display: inline-block;

        @media (max-width: 767px) {
            margin-top: 0px;
        }
    }

    .social-links {
        margin-top: 15px;
        margin-bottom: 35px;

        i {
            font-size: 25px;
            margin-right: 10px;
            color: #b7b7b7;
        }

        i.fa-instagram {
            font-size: 25px;
            color: #b7b7b7;
        }
    }

    .social__wrap {
        padding-top: 45px;
    }
}

footer ul li {
    padding: 0;
    margin: 0 0 5px;
}

.footer-title {
    color: #171f25;
    text-transform: uppercase;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
}

.m-0 {
    margin: 0px;
}

.m-b-0 {
    margin-bottom: 0px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-45 {
    margin-bottom: 45px;
}

.m-b-60 {
    margin-bottom: 60px;
}

.m-b-90 {
    margin-bottom: 90px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-45 {
    margin-top: 45px;
}

.m-t-60 {
    margin-top: 60px;
}

.m-t-90 {
    margin-top: 90px;
}

.m-t-120 {
    margin-top: 120px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-25 {
    margin-left: 25px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-35 {
    margin-left: 35px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-45 {
    padding-top: 45px;
}

.p-t-60 {
    padding-top: 60px;
}

.p-t-90 {
    padding-top: 90px;
}

.p-t-120 {
    padding-top: 120px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-b-45 {
    padding-bottom: 45px;
}

.p-b-60 {
    padding-bottom: 60px;
}

.p-b-90 {
    padding-bottom: 60px;
}

.p-b-120 {
    padding-bottom: 120px;
}

.p-15 {
    padding: 15px;
}

.p-30 {
    padding: 30px;
}

.p-45 {
    padding: 45px;
}

.p-60 {
    padding: 60px;
}

.p-90 {
    padding: 90px;
}

.p-120 {
    padding: 120px;
}

.p-l-45 {
    padding-left: 45px !important;
}

.tx-align-left {
    text-align: left !important;
}

.bg-orange {
    background: $color-orange;
}

.c-dark-gray {
    color: $color-dark-gray;
}

.c-light-gray {
    color: $color-light-gray;
}

.c-gray {
    color: $color-gray;
}

.c-purple {
    color: $color-purple !important;
}

.c-orange {
    color: $color-orange !important;
}


.c-red {
    color: #E53E30;
}

.c-pink {
    color: $color-pink;
}

a.c-blue, .c-blue {
    color: $color-blue;
}

a.c-white, .c-white {
    color: $color-white;
}

.underline {
    text-decoration: underline;
}

.font-weight-5 {
    font-weight: 500;
}

.font-bold {
    font-weight: bold;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

#modal-login {

    text-align: center;

    h5.error {
        color: $color-red;
        text-transform: none;
        margin: 30px 0px;
        font-size: 16px;
        font-weight: 600;
    }
    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        z-index: 99999 !important;
    }

    .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
        border: 0px;
    }

    .modal-body {
        padding: 0px;
    }

    .form-unit {
        padding: 30px;
    }

    .form-wrapper {

        padding-top: 90px;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        max-width: 440px;
        margin-bottom: 40px;

        @media (max-width: 767px) {
            padding-top: 45px;
        }

        h3 {
            font-weight: 500;
            font-size: 20px;
            color: #3c4858;
        }

        p {
            font-size: 16px;
            color: #8492a6;
            font-weight: 300;
            margin-bottom: 40px;
        }

        .input-wrapper {
            position: relative;
            margin-bottom: 15px;

            .forgot-password {
                padding-top: 3px;
                font-size: 12px;
                display: block;
                font-weight: 300;
            }

            .forgot-password a {
                color: #8492a6;
            }

            .auth-input {
                width: 100%;
                color: #677583;
                padding: 0 10px;
                height: 40px;
                background-color: rgba(252, 252, 252, 0.45);
                border: 1px solid #dde5ed;
                font-size: 14px;
                border-radius: 0px;
                -webkit-transition: border-color ease-in-out .15s;
                transition: border-color ease-in-out .15s;
            }
        }

    }

    .modal-subtext {
        font-size: 16px;
        font-weight: 400;
        color: #8492a6;
    }

    .latest-blog {

        .entry-wrap {
            position: absolute;
            bottom: 0px;
            left: 0px;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0.65) 98%, rgba(0, 0, 0, 0.65) 99%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0.65) 98%, rgba(0, 0, 0, 0.65) 99%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0.65) 98%, rgba(0, 0, 0, 0.65) 99%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
            padding-bottom: 30px;
        }

        .entry-content {
            position: relative;
            padding: 30px;
            text-align: left;
        }

        .entry-tag {
            padding: 5px 5px;
            color: #FFF;
            font-weight: 600;
            font-size: 11px;
        }

        a {

            .entry-title {
                color: #FFF;
                font-size: 28px;
                font-weight: bold;
                margin-bottom: 15px;
            }

            .entry-description {
                max-height: 50px;
                color: #FFF;
                font-size: 14px;
                line-height: 24px;
                font-weight: 500;
            }

            &:hover {
                color: $color-white;
                text-decoration: none;
            }
        }
    }

}

.display_desktop {
    display: block;
}

.display_mobile {
    display: none;
}

.relative {
    position: relative;
}

.page-list {

}

.page-entry {

    background: #FFF;

    .page-entry-body {

        min-height: 108px;
        padding: 10px;

        h5 {
            font-size: 16px;
            font-weight: bold;
            margin-top: 0px;
            margin-bottom: 5px;
        }

        p {
            font-size: 14px;
            margin-bottom: 0px;
            line-height: 18px;
        }

        .page-entry-actions {
            position: absolute;
            text-align: right;
            margin-top: 5px;
            bottom: 10px;
            right: 25px;
        }
    }

}

.page-thumb {
    width: 100%;
}

.brands-listing {

}

.brands-grid {

}

.brand-entry {
    border: 1px solid #dfdfdf;
    background: #FFF;
}

.brand-entry-header {
    width: 75px;
    margin: -30px auto;
    border: 1px solid #dfdfdf;
}

.brand-entry-body {
    text-align: center !important;
    padding: 45px 10px 10px;

    a {
        color: $color-dark-gray;
    }

    h5 {
        font-size: 16px;
        font-weight: bold;
        margin-top: 0px;
        margin-bottom: 5px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0px;
        line-height: 18px;
    }

    p.brand-entry-desc {
        margin-top: 15px;

        a:hover {
            text-decoration: none;
        }
    }
}

.brand-entry-footer {

    border-top: 1px solid #DFDFDF;
    padding: 10px;
    text-align: center;

    p {
        font-size: 12px;
        text-align: center;
        margin-bottom: 0px;
        font-weight: bold;
    }

    span {
        font-size: 14px;
    }
}

/** Brand Pages **/

.brand-page {

}

.brand-sidebar {

    // a {
    //   color: $color-dark-gray !important;
    // }

    padding-top: 45px;
    padding-left: 30px;
}

.brand-industry {
    margin: 0px;
    font-size: 14px;
}

.brand-name {
    font-size: 22px;
    margin-bottom: 0px;
    font-weight: 600;
}

.brand-logo {
    margin-bottom: 10px;
    width: 100%;

    img {
        margin: 0 auto;
        width: 200px;
    }

    .overlay_img {
        margin-top: -226px;
        position: relative;
    }
    .overlay_file {
        cursor: pointer;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: -219px;
        opacity: 0;
        position: relative;
        width: 200px;
    }
}

.brand-info-list {
    list-style: none;
    padding: 0px;
    margin: 15px 0px;

    li {
        font-size: 14px;

        i {
            font-size: 16px;
            margin-right: 10px;
            float: left;
            line-height: 23px;
        }

        p {
            margin: 0px;
        }
    }
}

.brand-page-body {
    font-size: 14px;
    min-height: 600px;
}

.brand-social-channel {
    overflow: hidden;
    padding-top: 15px;

    .social-icon-wrap {
        float: left;
        margin-right: 8px;

        .social-icon {
            float: left;
        }

        .social-count {
            padding-left: 35px;
            margin-top: 0px;
            margin-left: 3px;
            font-size: 14px;
            padding-top: 5px;
        }

        i {
            color: $color-white;
            border-radius: 50%;
            font-size: 16px;
            line-height: 32px;
            text-align: center;
            width: 32px;
            height: 32px;

            &.fa-facebook {
                background: #3b5998;
            }

            &.fa-twitter {
                background: #1da1f2;
            }

            &.fa-instagram {
                background: #e4405f;
            }

            &.fa-youtube-play {
                background: #cd201f;
            }
        }
    }
}

.brand-interests {
    list-style: none;
    padding: 0px;
    margin: 30px 0px 0px;

    li.interest-item {
        float: left;
        text-align: center;
        padding-right: 45px;
        margin-bottom: 30px;

        h6 {
            text-transform: none;
            font-weight: 400;
            font-size: 14px;
            margin-top: 5px;
        }
    }
}

.ks-pricing-plans-page > .ks-header {
    margin-bottom: 60px;
}

.ks-pricing-plans-page > .ks-header > .ks-name {
    font-size: 36px;
    font-weight: normal;
    margin-bottom: 15px;
}

.ks-pricing-plans-page > .ks-header > .ks-description {
    font-size: 16px;
}

.ks-plan {
    padding: 40px 50px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: rgba(57, 80, 155, 0.05);
    border: solid 1px rgba(57, 81, 155, 0.1);
    margin-right: 30px;
    position: relative;
}

.ks-plan:before {
    content: '';
    position: absolute;
    height: 10px;
    top: -1px;
    left: -1px;
    right: -1px;
    background: #e2e4f0;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}

.ks-plan:last-child {
    margin-right: 0;
}

.ks-plan.ks-info {
    background-color: #ffffff;
    border: solid 1px rgba(57, 81, 155, 0.1)
}

.ks-plan.ks-orange {
    background-color: #ffffff;
    border: solid 1px rgba(57, 81, 155, 0.1)
}

.ks-plan.ks-info:before {
    background: #444f58;
}

.ks-plan.ks-orange:before {
    background: #ff9800;
}

.ks-plan > .ks-header {

    margin-bottom: 45px;
}

.ks-plan > .ks-header > .ks-name {
    font-size: 18px;
    margin-bottom: 24px;
    text-align: center;
}

.ks-plan > .ks-header > .ks-price {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.ks-plan > .ks-header > .ks-price > .ks-currency {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
}

.ks-plan > .ks-header > .ks-price > .ks-amount {
    font-size: 64px;
    line-height: 52px;
    font-weight: normal;
    margin-right: 7px;
    margin-left: 7px;
}

.ks-plan > .ks-header > .ks-price > .ks-period {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    font-size: 14px;
    line-height: 14px;
    color: #858585;
}

.ks-plan > .ks-body > .ks-description {
    font-size: 14px;
    text-align: center;
}

.ks-plan > .ks-body > .ks-list {
    height: 150px;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 60px;
}

.ks-plan > .ks-body > .ks-list > li {
    font-size: 14px;
    margin-bottom: 10px;
}

.ks-plan > .ks-body > .ks-list > li:last-child {
    margin-bottom: 0;
}

.ks-plan > .ks-body > .ks-list > li:before {
    content: "\f17b";
    font-family: "LineAwesome";
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: rgba(58, 82, 155, 0.3);
    margin-right: 15px;
    position: relative;
    top: 2px;
}

.ks-plan > .ks-body > .ks-list.ks-success > li:before {
    color: #2ecc71;
}

.ks-plans > .subscription-toggle {
    position: absolute;
    right: 0;
    top: -100px;
}

.ks-plans > .subscription-toggle > .ks-checkbox-text-slider {
    text-align: right;
}

.ks-plans > .subscription-toggle > .ks-description {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: rgba(46, 204, 113, 0.1);
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    color: #2ecc71;
    padding: 7px 15px;
    margin-top: 10px;
    text-transform: uppercase;
}

.ks-custom-plans {
    padding: 90px 0px;
    background: url('../img/brands_home_bg3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 767px) {
    .ks-custom-plans {
        padding: 60px 30px;
    }
}

.ks-custom-plans .ks-custom-plans-title {
    margin-bottom: 45px;
}

.ks-checkbox-text-slider > .ks-checkbox-slider {
    margin-left: 6px;
    margin-right: 6px;
}

.ks-color-info {
    color: #22a7f0 !important;
}

.ks-checkbox-slider-a {
    width: 40px;
    height: 10px;
    background: #555;
    position: relative;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
}

.ks-checkbox-slider-a input[type=checkbox] {
    visibility: hidden;
}

.ks-checkbox-slider-a input[type=checkbox]:checked + label {
    left: 27px;
}

.ks-checkbox-slider-a > label {
    display: block;
    width: 16px;
    height: 16px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    cursor: pointer;
    position: absolute;
    top: -3px;
    left: -3px;
    background: #ccc;
}

.ks-checkbox-slider {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    width: 44px;
    height: 24px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.2);
    position: relative;
    cursor: pointer;
}

.ks-checkbox-slider > .ks-indicator {
    display: inline-block;
    width: 16px;
    height: 16px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(56, 81, 155, 0.3);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    position: absolute;
    top: 3px;
    z-index: 1;
    left: 4px;
}

.ks-checkbox-slider.ks-disabled {
    opacity: .4;
}

.ks-checkbox-slider input[type=checkbox] {
    visibility: hidden;
}

.ks-checkbox-slider input[type=checkbox]:checked + .ks-indicator {
    left: 22px;
}

.ks-checkbox-slider input[type=checkbox]:checked + .ks-indicator {
    background: #3a529b;
}

.ks-checkbox-slider.ks-primary {
    border: solid 1px #3a529b;
}

.ks-checkbox-slider.ks-primary > .ks-indicator {
    background: rgba(56, 81, 155, 0.3);
}

.ks-checkbox-slider.ks-primary input[type=checkbox]:checked + .ks-indicator {
    background: #3a529b;
}

.ks-checkbox-slider.ks-success {
    border: solid 1px #2ecc71;
}

.ks-checkbox-slider.ks-success > .ks-indicator {
    background-color: rgba(46, 204, 113, 0.4);
}

.ks-checkbox-slider.ks-success input[type=checkbox]:checked + .ks-indicator {
    background: #2ecc71;
}

.ks-checkbox-slider.ks-info {
    border: solid 1px #22a7f0;
}

.ks-checkbox-slider.ks-info > .ks-indicator {
    background-color: rgba(33, 166, 240, 0.4);
}

.ks-checkbox-slider.ks-info input[type=checkbox]:checked + .ks-indicator {
    background: #22a7f0;
}

.ks-checkbox-slider.ks-warning {
    border: solid 1px #debb0c;
}

.ks-checkbox-slider.ks-warning > .ks-indicator {
    background-color: rgba(222, 186, 12, 0.4);
}

.ks-checkbox-slider.ks-warning input[type=checkbox]:checked + .ks-indicator {
    background: #debb0c;
}

.ks-checkbox-slider.ks-danger {
    border: solid 1px #ec644b;
}

.ks-checkbox-slider.ks-danger > .ks-indicator {
    background-color: rgba(236, 100, 75, 0.4);
}

.ks-checkbox-slider.ks-danger input[type=checkbox]:checked + .ks-indicator {
    background: #ec644b;
}

.ks-checkbox-slider.ks-on-off {
    width: 61px;
    position: relative;
}

.ks-checkbox-slider.ks-on-off > .ks-on, .ks-checkbox-slider.ks-on-off > .ks-off {
    line-height: 22px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    position: absolute;
}

.ks-checkbox-slider.ks-on-off > .ks-on {
    display: none;
    left: 15px;
}

.ks-checkbox-slider.ks-on-off > .ks-off {
    left: 27px;
}

.ks-checkbox-slider.ks-on-off input[type=checkbox]:checked + .ks-indicator {
    left: 40px;
}

.ks-checkbox-slider.ks-on-off input[type=checkbox]:checked + .ks-indicator + .ks-on {
    display: inline-block;
}

.ks-checkbox-slider.ks-on-off input[type=checkbox]:checked + .ks-indicator + .ks-on + .ks-off {
    display: none;
}

.ks-checkbox-slider.ks-on-off.ks-solid > .ks-indicator {
    background: #fff;
}

.ks-checkbox-slider.ks-on-off.ks-solid > .ks-on, .ks-checkbox-slider.ks-on-off.ks-solid > .ks-off {
    color: #fff;
}

.ks-checkbox-slider.ks-on-off.ks-solid input[type=checkbox]:checked + .ks-indicator {
    background: #fff;
    left: 40px;
}

.ks-checkbox-slider.ks-on-off.ks-solid.ks-primary {
    border: solid 1px #3a529b;
    background: #3a529b;
}

.ks-checkbox-slider.ks-on-off.ks-solid.ks-success {
    border: solid 1px #2ecc71;
    background: #2ecc71;
}

.ks-checkbox-slider.ks-on-off.ks-solid.ks-info {
    border: solid 1px #22a7f0;
    background: #22a7f0;
}

.ks-checkbox-slider.ks-on-off.ks-solid.ks-warning {
    border: solid 1px #debb0c;
    background: #debb0c;
}

.ks-checkbox-slider.ks-on-off.ks-solid.ks-danger {
    border: solid 1px #ec644b;
    background: #ec644b;
}

.ks-checkbox-text-slider > .ks-text {
    font-size: 16px;
    display: inline-block;
    position: relative;
    top: 1px;
}

.ks-checkbox-text-slider > .ks-checkbox-slider {
    margin-left: 6px;
    margin-right: 6px;
}

@media (max-width: 767px) {

    .ks-plans {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .ks-plan {
        margin: 0;
        margin-bottom: 30px;
    }

    .ks-plans > .subscription-toggle {
        position: static;
    }

    .ks-plans > .subscription-toggle > .ks-checkbox-text-slider {
        text-align: center;
    }
}

.carousel-control.left,
.carousel-control.right {
    background: none !important;
}

.carousel-control .icon-prev, .carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
    width: auto !important;
    height: auto !important;
    width: auto;
    min-width: 0;
}

.carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
    opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-control {
    z-index: 2;
    opacity: 1.0;
}

@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
