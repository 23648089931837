/* Colors
 **********************/

$color-orange: #F89201;
$color-light-gray: #DBDBDB;
$color-gray: #B2B5BC;
$color-dark-gray: #444F58;/*#637280;*/
$color-pink: #EF3695;
$color-white: #FFFFFF;
$color-dark-blue: #2E2E7F;
$color-red: #CA3232;
$color-blue: #367EEB;
$color-purple: #7300ef;
$color-purple-hover: #6702d4;
/* Fonts
 **********************/

//  $font-primary: 'Titillium Web', sans-serif;
 $font-primary: 'Test', sans-serif;
$font-Bebas-Neue-Regular: "Bebas Neue Regular", Arial, sans-serif;
$font-Bebas-Neue-Light: "Bebas Neue Light", Arial, sans-serif;
$font-Bebas-Neue-Thin: "Bebas Neue Thin", Arial, sans-serif;
$font-Bebas-Neue-Bold: "Bebas Neue Bold", Arial, sans-serif;
$font-Roboto-Condensed: "Roboto Condensed", Arial, sans-serif;
$font-Raleway: "Raleway", Arial, sans-serif;
$font-PT-Sans: "PT Sans", sans-serif;



/*  Frontrow font */

[class^="svg-"]:before,
[class*=" svg-"]:before {
  text-transform: none !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-family: "frontrow" !important;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.svg-login:before {
  content: "\63";
}

.svg-network:before {
  content: "\64";
}

.svg-pen:before {
  content: "\65";
}

.svg-tieup:before {
  content: "\66";
}

.svg-down:before {
  content: "\67";
}

.svg-discover:before {
  content: "\68";
}

.svg-facebook:before {
  content: "\69";
}

.svg-twitter:before {
  content: "\6a";
}

.svg-youtube-alt:before {
  content: "\6b";
}

.svg-youtube:before {
  content: "\6c";
}

.svg-instagram:before {
  content: "\6d";
}

.svg-instruction:before {
  content: "\6f";
}

.svg-add:before {
  content: "\6e";
}

.svg-logout:before {
  content: "\70";
}

.svg-automotive:before {
  content: "\72";
}

.svg-close:before {
  content: "\73";
}

.svg-envelope:before {
  content: "\75";
}

.svg-family:before {
  content: "\76";
}

.svg-phone:before {
  content: "\77";
}

.svg-right:before {
  content: "\78";
}

.svg-left:before {
  content: "\79";
}

.svg-snapchat:before {
  content: "\7a";
}

.svg-agency:before {
  content: "\61";
}

.svg-influencer:before {
  content: "\62";
}

.svg-sports:before {
  content: "\71";
}

.svg-photography:before {
  content: "\74";
}

.svg-art-design:before {
  content: "\41";
}

.svg-beauty:before {
  content: "\42";
}

.svg-business:before {
  content: "\43";
}

.svg-technology:before {
  content: "\44";
}

.svg-travel:before {
  content: "\45";
}

.svg-cars:before {
  content: "\46";
}

.svg-education:before {
  content: "\47";
}

.svg-entertainment:before {
  content: "\48";
}

.svg-fashion:before {
  content: "\49";
}

.svg-food:before {
  content: "\4a";
}

.svg-government_politics:before {
  content: "\4b";
}

.svg-lifestyle:before {
  content: "\4c";
}

.svg-media_press:before {
  content: "\4d";
}

.svg-moms_dads:before {
  content: "\4e";
}

.svg-music:before {
  content: "\4f";
}

.svg-socialize:before {
  content: "\50";
}

.svg-testers:before {
  content: "\51";
}

.fr-icons {
  font-size: 50px;
  margin: 0 auto;
  line-height: 40px;
  &:before {
    background: none !important;
    color: $color-light-gray;
  }
}

/*  End Frontrow font */